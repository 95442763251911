import * as yup from 'yup';
export interface InventoryStockingLevels {
  availableStockDimension: StockignWarehousePriority;
  replenishmentDimension: StockignWarehousePriority;
  warehousePriority: StockignWarehousePriority;
  createdByFullName: string;
  createdByUserId: string;
  createdDateTime: string;
  reasonCodes: string[];
  description: string;
  division: {
    business: {
      dataAreaId: string;
      erpId: string;
      id: string;
      name: string;
    };
    description: string;
    documentType: string;
    erpId: string;
    id: string;
    name: string;
  };
  documentType: string;
  id: string;
  isActive: boolean;
  isDeleted: boolean;
  modifiedByFullName: string;
  modifiedByUserId: string;
  modifiedDateTime: string;
  name: string;
  partitionKey: string;
  partitionKeyDescription: string;
  products: StockingProducts[];
  stockingLevelType: string;
}

export interface StockingProducts {
  productNumber: string;
  warehouseId: string;
  id?: string;
  manufacturer: string;
  category: string;
  minimumQuantity: number;
  maximumQuantity: number;
  isDeleted: boolean;
  reasonCode: string;
  notes?: string;
}

export interface InventoryStockingLevelsPayload {
  name: string;
  description: string;
  stockingLevelType: string;
  products?: StockingProducts[];
  replenishmentDimensionId?: string | null;
  availableStockDimensionId?: string | null;
  warehousePriorityId?: string | null;
  isDeleted?: boolean | null;
  reasonCodes: string[];
  notes?: string | null;
  exit?: boolean | null;
  isActive?: boolean | null;
}

export interface SonnyFriendlyParams {
  nameContains?: string;
  descriptionContains?: string;
  returnDeletedDataOnly?: boolean;
  includeDeletedData?: boolean;
  offset: number;
  orderByDirection?: string;
  orderByField?: string;
  overrideSkipTake?: boolean;
}

export interface StockignWarehousePriority {
  name: string;
  description: string;
  id: string;
}

export const inventoryStockingLevelsSchema: yup.SchemaOf<InventoryStockingLevelsPayload> = yup.object({
  name: yup.string().label('Name').required(),
  description: yup.string().label('Description').required(),
  stockingLevelType: yup.string().label('Stocking Level Type').required(),
  replenishmentDimensionId: yup.string().label('Replenishment Dimension').nullable(),
  availableStockDimensionId: yup.string().label('Available Stock Dimension').nullable(),
  warehousePriorityId: yup.string().label('Warehouse Priority').nullable(),
  notes: yup.string().label('Notes').nullable(),
  isDeleted: yup.boolean().nullable(),
  isActive: yup.boolean().nullable(),
  products: yup.array(),
  reasonCodes: yup.array().min(1).required(),
  exit: yup.boolean().nullable()
});
