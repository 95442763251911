import { Card, Col, message, Row, Space, Spin, Typography } from 'antd';
import { AvailableStockingDimensionsSelectField } from 'components/atoms/AvailableStockingDimensionsSelectField';
import { FieldInput } from 'components/atoms/FieldInput';
import { FieldInputSelect } from 'components/atoms/FieldInputSelect';
import { Loader } from 'components/atoms/Loader';
import { ReasonCodeInput } from 'components/atoms/ReasonCodeInput';
import { ReasonCodeList } from 'components/atoms/ReasonCodeList';
import { ReplenishmentDimensionsSelectField } from 'components/atoms/ReplenishmentDimensionsSelectField.tsx';
import { WarehousePrioritySelectField } from 'components/atoms/WarehousePrioritySelectField';
import { CreatePageControls } from 'components/molecules/CreatePageControls';
import { StockingInventoryLevelsTable } from 'components/organisms/StockingInventoryLevelsTable';
import { FormikProvider, useFormik } from 'formik';
import { InventoryStockingLevelsPayload, inventoryStockingLevelsSchema } from 'models/InventoryStockingLevelsModel';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useCreateInventoryStockingLevelMutation } from 'redux/services/sonnyFriendly/sonnyFriendly';

const GeneralSection: React.FC<{ values: InventoryStockingLevelsPayload }> = ({ values }) => {
  return (
    <Card
      style={{ height: '100%' }}
      headStyle={{ background: 'rgb(206, 213, 242)' }}
      title={
        <Typography.Title level={4} style={{ fontWeight: 400, fontSize: 22 }}>
          General
        </Typography.Title>
      }>
      <Row gutter={[30, 10]}>
        <Col span={12}>
          <Space direction="vertical" style={{ width: '100%' }} size={15}>
            <FieldInput fieldName="name" label="Name" />
          </Space>
        </Col>
        <Col span={12}>
          <Space direction="vertical" style={{ width: '100%' }} size={15}>
            <FieldInput fieldName="description" label="Description" />
          </Space>
        </Col>
        <Col span={12}>
          <Space direction="vertical" style={{ width: '100%' }} size={15}>
            <FieldInputSelect options={['Sparing', 'Deployment']} fieldName="stockingLevelType" label="Stocking Level Type" />
          </Space>
        </Col>
        <Col span={12}>
          <Space direction="vertical" style={{ width: '100%' }} size={15}>
            <WarehousePrioritySelectField fieldName="warehousePriorityId" label="Warehouse Priority" />
          </Space>
        </Col>
        <Col span={12}>
          <Space direction="vertical" style={{ width: '100%' }} size={15}>
            <ReplenishmentDimensionsSelectField fieldName="replenishmentDimensionId" label="Replenishment Dimensions" />
          </Space>
        </Col>
        <Col span={12}>
          <Space direction="vertical" style={{ width: '100%' }} size={15}>
            <AvailableStockingDimensionsSelectField fieldName="availableStockDimensionId" label="Available Stocking Dimensions" />
          </Space>
        </Col>
        <Col span={12}>
          <Space direction="vertical" style={{ width: '100%' }} size={15}>
            <ReasonCodeInput />
            {!!values.reasonCodes.length && <ReasonCodeList />}
          </Space>
        </Col>
      </Row>
    </Card>
  );
};

export const CreateStockingLevelPage: React.FC = () => {
  const [createStockingLevel] = useCreateInventoryStockingLevelMutation();
  const navigate = useNavigate();
  const formik = useFormik<InventoryStockingLevelsPayload>({
    enableReinitialize: true,
    validationSchema: inventoryStockingLevelsSchema,
    initialValues: {
      name: '',
      description: '',
      stockingLevelType: '',
      products: [],
      availableStockDimensionId: '',
      replenishmentDimensionId: '',
      warehousePriorityId: '',
      reasonCodes: [],
      notes: '',
      isDeleted: false,
      isActive: true
    },
    onSubmit: async (values) => {
      const { exit, ...payload } = values;

      try {
        const response = await createStockingLevel({ payload }).unwrap();

        message.success(`${response.name} succesfully created`);
        if (exit) {
          navigate('/');
        } else {
          navigate(`/stocking-level/${response.id}`);
        }
      } catch (err) {
        console.log(err);
        message.error((err as { data: { errorMessage: string } }).data.errorMessage);
      }
    }
  });

  const isDisabled = Boolean(
    !formik.values.name ||
      !formik.values.description ||
      !formik.values.replenishmentDimensionId ||
      !formik.values.availableStockDimensionId ||
      !formik.values.warehousePriorityId ||
      !formik.values.stockingLevelType ||
      !formik.values.reasonCodes.length
  );

  return (
    <FormikProvider value={formik}>
      <Spin spinning={formik.isSubmitting} indicator={<Loader message="Creating inventory stocking level" />}>
        <Space direction="vertical" style={{ width: '100%', paddingBottom: 100 }}>
          <CreatePageControls submitButtonProps={{ handleSubmit: () => formik.submitForm(), disabled: isDisabled }} title="Create Stocking Level" />
          <GeneralSection values={formik.values} />
          <StockingInventoryLevelsTable reasonCodeOptions={formik.values.reasonCodes} />
        </Space>
      </Spin>
    </FormikProvider>
  );
};
