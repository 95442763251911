import { Col, Select, SelectProps } from 'antd';
import { FormLabel } from 'components/UI/FormLabel';
import { Field, FieldProps } from 'formik';
import React, { CSSProperties } from 'react';

interface SelectInputFieldProps extends SelectProps {
  isLoading?: boolean;
  fieldName: string;
  labelStyle?: CSSProperties;
  label: string;
  options: { label: string; value: string }[] | undefined;
  isCard?: boolean;
}

export const SelectInput: React.FC<SelectInputFieldProps> = ({ fieldName, label, labelStyle, options, isLoading, isCard, ...rest }) => {
  return (
    <Field name={fieldName}>
      {({
        form: { setFieldTouched, setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
        meta
      }: FieldProps): JSX.Element => (
        <Col span={24}>
          <FormLabel style={labelStyle} label={label} />
          <Select
            loading={isLoading}
            filterSort={(optionA, optionB): number => ((optionA?.label as string) ?? '').toLowerCase().localeCompare(((optionB?.label as string) ?? '').toLowerCase())}
            options={options}
            showSearch
            onFocus={(): void => setFieldTouched(fieldName, true)}
            style={{ width: isCard ? 150 : '100%' }}
            onChange={(val): void => setFieldValue(fieldName, val)}
            status={meta.error && meta.touched ? 'error' : undefined}
            value={meta.value}
            {...rest}
          />
          <div className={meta.touched && meta.error ? 'field-error-show' : 'field-error-hide'}>{meta.error}</div>
        </Col>
      )}
    </Field>
  );
};
