import { Radio, RadioChangeEvent } from 'antd';
import { FC } from 'react';
import { useDispatch } from 'react-redux';
import { setIsActive } from 'redux/slices/filterSlice';

export const StockingLevelsActiveFilter: FC = () => {
  const dispatch = useDispatch();
  const handleFilterChange = (e: RadioChangeEvent): void => {
    switch (e.target.value) {
      case 'active':
        dispatch(setIsActive(true));
        break;

      case 'deleted':
        dispatch(setIsActive(false));
        break;

      case 'both':
        dispatch(setIsActive(undefined));
        break;

      default:
        dispatch(setIsActive(undefined));
    }
  };

  return (
    <Radio.Group style={{ width: 250 }} onChange={handleFilterChange} defaultValue={'active'}>
      <Radio.Button value="active">Active</Radio.Button>
      <Radio.Button value="deleted">Deleted</Radio.Button>
      <Radio.Button value="both">All Data</Radio.Button>
    </Radio.Group>
  );
};
