import { DownOutlined, RightOutlined } from '@ant-design/icons';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { Col, Row, Table } from 'antd';
import { Loader } from 'components/atoms/Loader';
import { WimsicalError } from 'components/atoms/WimsicalError';
import { WarehousePriorities } from 'models/WarehousePriorityModel';
import React, { LegacyRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { animated, useSpring } from 'react-spring';
import { useMeasure } from 'react-use';
import { useGetWarehousePrioritiesQuery } from 'redux/services/sonnyFriendly/sonnyFriendly';
import { useAppSelector } from 'redux/store';

const WarehouseTable = ({ warehouses }: { warehouses: WarehousePriorities[] }): JSX.Element => {
  const [ref, { height }] = useMeasure();

  const expandAnimation = useSpring({
    config: { friction: 12, bounce: 0 },
    height: height || 0
  });

  const columns: { title: string; dataIndex: string; key: string; ellipsis: boolean; width?: number; render?: (record: string) => JSX.Element }[] = [
    {
      title: 'Group Name',
      dataIndex: 'groupName',
      key: 'groupName',
      ellipsis: true
    },
    {
      title: 'Primary Replenishment Warehouse',
      dataIndex: 'primaryReplenishmentWarehouseId',
      key: 'primaryReplenishmentWarehouseId',
      ellipsis: true
    },
    {
      title: 'Warehouse',
      dataIndex: 'warehouseId',
      key: 'warehouseId',
      ellipsis: true
    },
    {
      title: 'priority',
      dataIndex: 'priority',
      key: 'priority',
      ellipsis: true
    }
  ];

  return (
    <animated.div style={{ ...{ minHeight: 10, overflow: 'hidden' }, ...expandAnimation }}>
      <div ref={ref as LegacyRef<HTMLDivElement>}>
        <Table bordered pagination={false} style={{ width: 1000 }} size="small" columns={columns} dataSource={warehouses} />
      </div>
    </animated.div>
  );
};

export const WarehousePriorityTable: React.FC = () => {
  const { warehousePriorityParams } = useAppSelector((state) => state);
  const [currentPage, setCurrentPage] = useState(1);

  const { data, isLoading, isFetching, isError, error } = useGetWarehousePrioritiesQuery({
    params: { ...warehousePriorityParams, offset: currentPage === 1 ? 0 : currentPage * 25 - 25, orderByDirection: 'descending', orderByField: 'createdDateTime' }
  });

  const columns: { title: string; dataIndex: string; key: string; ellipsis: boolean; width?: number; render?: (record: string) => JSX.Element }[] = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      ellipsis: true,
      render: (record): JSX.Element => {
        const priorityId = data?.data.find((item) => item.name === record)?.id;

        return <Link to={`warehouse-priority/${priorityId}`}>{record}</Link>;
      }
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      ellipsis: true
    }
  ];

  if (isLoading || isFetching) return <Loader />;
  if (isError) {
    const queryError = error as FetchBaseQueryError;

    return (
      <Row justify="center" style={{ width: '100%' }}>
        <Col span={24}>
          <WimsicalError status={typeof queryError.status === 'number' ? queryError.status : 500} message={''} />
        </Col>
      </Row>
    );
  }

  return (
    <Table
      style={{ marginTop: 5 }}
      dataSource={data?.data}
      columns={columns}
      rowClassName="editable-row"
      pagination={{ total: data?.totalCount, size: 'small', current: currentPage, onChange: (page) => setCurrentPage(page), hideOnSinglePage: true, defaultPageSize: 25 }}
      rowKey={(row): string => `${row.name}`}
      expandable={{
        columnWidth: 30,
        expandIcon: ({ onExpand, expanded, record }): JSX.Element | undefined => {
          return expanded ? (
            <DownOutlined
              className="product-table__arrow"
              style={{
                fontSize: 13,
                padding: 5,
                position: 'absolute',
                transform: 'translateY(-50%)',
                zIndex: 9999,
                width: 24,
                height: 24
              }}
              onClick={(e): void => {
                e.preventDefault();
                e.stopPropagation();
                onExpand(record, e);
              }}
            />
          ) : (
            <RightOutlined
              className="product-table__arrow"
              style={{
                fontSize: 13,
                padding: 5,
                position: 'absolute',
                transform: 'translateY(-50%)',
                zIndex: 9999,
                width: 24,
                height: 24
              }}
              onClick={(e): void => {
                e.preventDefault();
                e.stopPropagation();
                onExpand(record, e);
              }}
            />
          );
        },
        expandedRowRender: (record): React.ReactNode => {
          return <WarehouseTable warehouses={record?.warehousePriorities} />;
        }
      }}
    />
  );
};
