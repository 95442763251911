import { Button, Form, Input } from 'antd';
import { useFormik, useFormikContext } from 'formik';
import { rest } from 'lodash';
import { InventoryStockingLevelsPayload } from 'models/InventoryStockingLevelsModel';
import { FC } from 'react';
import * as yup from 'yup';

interface Props {
  isAdmin?: boolean;
}

const subscribeSchema = yup.object({
  reasonCode: yup.string().label('Reason Code')
});

export const ReasonCodeInput: FC<Props> = ({ isAdmin }) => {
  const { values: headerValues, setValues } = useFormikContext<InventoryStockingLevelsPayload>();

  const { touched, values, handleBlur, handleChange, errors, handleSubmit, setErrors } = useFormik({
    validationSchema: subscribeSchema,
    initialValues: {
      reasonCode: ''
    },
    onSubmit: ({ reasonCode }, { resetForm }) => {
      if (headerValues.reasonCodes.includes(reasonCode)) {
        setErrors({ reasonCode: 'Reason code already exists in list' });

        return;
      }
      if (!reasonCode) {
        setErrors({ reasonCode: 'Reason code cannot be empty' });

        return;
      }
      setValues((prev) => ({
        ...prev,
        reasonCodes: [...prev.reasonCodes, reasonCode]
      }));
      resetForm();
    }
  });

  const handleEnterPressed = (): void => {
    if (!errors.reasonCode) {
      handleSubmit();
    }
  };

  // const handleEmailChange = (ev: React.ChangeEvent<HTMLInputElement>): void => {
  //   setVal(ev.target.value);
  // };

  return (
    <Form.Item labelAlign="left" validateStatus={errors.reasonCode ? 'error' : 'success'} help={touched ? errors.reasonCode : undefined} style={{ marginBottom: 0 }}>
      <Input.Group style={{ display: 'flex' }} compact>
        <Input
          {...rest}
          disabled={isAdmin}
          onPressEnter={handleEnterPressed}
          value={values.reasonCode || undefined}
          onChange={handleChange('reasonCode')}
          onBlur={handleBlur('reasonCode')}
          style={{ flex: 1 }}
        />
        <Button type="primary" disabled={Boolean(errors.reasonCode || isAdmin)} onClick={(): void => handleSubmit()}>
          Add
        </Button>
      </Input.Group>
    </Form.Item>
  );
};
