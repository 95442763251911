import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { Button, Col, List, message, Row, Space, Typography } from 'antd';
import { InventoryStockingDescriptionContainsFilter } from 'components/atoms/filters/InventoryStockingDescriptionContainsFilter';
import { InventoryStockingNameContainsFilter } from 'components/atoms/filters/InventoryStockingNameContainsFilter';
import { Loader } from 'components/atoms/Loader';
import { StockingLevelRecycleCard } from 'components/atoms/StockingLevelRecycleCard';
import { WimsicalError } from 'components/atoms/WimsicalError';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useGetInventoryStockingLevelsQuery, useRecoverInventoryStockingLevelMutation } from 'redux/services/sonnyFriendly/sonnyFriendly';
import { setSelectedStockingLevels } from 'redux/slices/selectedSotckingLevelsSlice';
import { useAppSelector } from 'redux/store';

export const StockingLevelsRecycleBinPage = (): JSX.Element => {
  const { inventoryStockingParams } = useAppSelector((state) => state);
  const { selectedStockingLevels } = useAppSelector((state) => state.selectedStockingLevels);
  const [recoverStockingLevel, { isLoading: isRecovering }] = useRecoverInventoryStockingLevelMutation();
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const { data, isLoading, isFetching, isError, error } = useGetInventoryStockingLevelsQuery({ ...inventoryStockingParams, returnDeletedDataOnly: true });

  const handleRestore = (): void => {
    try {
      selectedStockingLevels.forEach(async (level) => {
        await recoverStockingLevel(level.id);
      });
    } catch (err) {
      console.log(err);
      message.error((err as { data: { errorMessage: string } }).data.errorMessage);
    }
  };

  useEffect(() => {
    return (): void => {
      dispatch(setSelectedStockingLevels([]));
    };
  }, [dispatch]);
  if (isError) {
    const queryError = error as FetchBaseQueryError;

    return (
      <Row justify="center" style={{ width: '100%' }}>
        <Col span={24}>
          <WimsicalError status={typeof queryError.status === 'number' ? queryError.status : 500} message={''} />
        </Col>
      </Row>
    );
  }

  return (
    <Row gutter={[5, 5]}>
      <Col span={24}>
        <Row justify="space-between">
          <Col>
            <Typography.Title style={{ fontWeight: 300, margin: 0, padding: 0 }} level={5}>
              Search Inventory Stocking Levels - Recycle Bin
            </Typography.Title>
          </Col>
          <Col>
            <Button onClick={(): void => navigate('/')}>Back to Home</Button>
          </Col>
        </Row>
      </Col>
      <Col span={24}>
        <Row style={{ padding: 12, background: 'white' }}>
          <Space>
            <InventoryStockingNameContainsFilter />
            <InventoryStockingDescriptionContainsFilter />
          </Space>
        </Row>
      </Col>
      <Col style={{ marginTop: 5 }} span={24}>
        <Row justify="space-between">
          <Col>
            <Space style={{ width: '100%' }}>
              <Button onClick={() => dispatch(setSelectedStockingLevels(data?.data ?? []))}>Select All</Button>
              <Button onClick={() => dispatch(setSelectedStockingLevels([]))} type="primary">
                Clear All
              </Button>
            </Space>
          </Col>
          <Col>
            <Button loading={isRecovering} onClick={handleRestore} disabled={!selectedStockingLevels.length}>
              Restore Selected
            </Button>
          </Col>
        </Row>
      </Col>
      <Col span={24}>
        <Row>
          <List
            loading={{ spinning: isLoading || isFetching, indicator: <Loader /> }}
            dataSource={data?.data}
            grid={{ gutter: 4, column: 4, xs: 1, sm: 1, md: 2, lg: 2, xl: 3, xxl: 4 }}
            style={{ width: '100%', margin: 0, padding: 0 }}
            renderItem={(item): React.ReactNode => <StockingLevelRecycleCard item={item} />}
          />
        </Row>
      </Col>
    </Row>
  );
};
