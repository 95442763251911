import { DeleteOutlined, EditOutlined, ToTopOutlined, UnorderedListOutlined } from '@ant-design/icons';
import { Button, Card, Col, List, Modal, Row, Space, Table, Tooltip, Typography, message } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { ExportProductsButton } from 'components/atoms/ExportProductsButton';
import { FieldInputNumber } from 'components/atoms/FieldInputNumber';
import { FieldInputTextArea } from 'components/atoms/FieldInputTextArea';
import { ImportProducts } from 'components/atoms/ImportProducts';
import { Loader } from 'components/atoms/Loader';
import { SelectInput } from 'components/atoms/SelectInput';
import { StockingLevelTableFilters } from 'components/molecules/StockingLevelTableFilters';
import { Form, FormikProvider, useField, useFormik } from 'formik';
import { useGetFilteredData } from 'hooks/useGetFilteredData';
import { AuditHistory } from 'models/AuditHisotry';
import { StockingProducts } from 'models/InventoryStockingLevelsModel';
import { FC, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useLazyGetLineItemAuditHistoryQuery } from 'redux/services/sonnyFriendly/sonnyFriendly';
import { useAppSelector } from 'redux/store';
import { ProductsSearchModal } from './ProductsSearchModal';

interface Props {
  reasonCodeOptions: string[];
}

export const StockingInventoryLevelsTable: FC<Props> = ({ reasonCodeOptions }) => {
  const [{ value: productsTableData }] = useField<StockingProducts[]>('products');

  const { stockingLevelFilters } = useAppSelector((state) => state);

  const { filteredData } = useGetFilteredData(stockingLevelFilters, productsTableData);

  const [isEditOpen, setIsEditOpen] = useState<boolean>(false);
  const [recordToEdit, setRecordToEdit] = useState<StockingProducts | undefined>();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isAuditOpen, setIsAuditOpen] = useState(false);
  const [isRestoreOpen, setIsRestoreOpen] = useState(false);
  const [auditHistory, setAuditHistory] = useState<AuditHistory | undefined>(undefined);
  const { stockingLevelId } = useParams();

  const [getAuditHistory, { isLoading, isFetching }] = useLazyGetLineItemAuditHistoryQuery();

  const [{ value: record }, , { setValue: setRecord }] = useField<StockingProducts>(`products[${productsTableData.findIndex((rec) => rec === recordToEdit)}]`);

  const editWarehouse = useFormik<StockingProducts>({
    enableReinitialize: true,
    initialValues: {
      productNumber: record?.productNumber ?? '',
      warehouseId: record?.warehouseId ?? '',
      manufacturer: record?.manufacturer ?? '',
      category: record?.category ?? '',
      minimumQuantity: record?.minimumQuantity ?? 0,
      maximumQuantity: record?.maximumQuantity ?? 0,
      isDeleted: false,
      reasonCode: record?.reasonCode ?? '',
      notes: record?.notes ?? ''
    },
    onSubmit: (newValues) => {
      setRecord({ ...record, ...newValues });
      setIsEditOpen(false);
      editWarehouse.resetForm();
    }
  });

  const handleDeleteRow = (): void => {
    if (!record) return;
    setRecord({ ...record, isDeleted: true });
    setIsDeleteModalOpen(false);
  };
  const handleRestoreRow = (): void => {
    if (!record) return;
    setRecord({ ...record, isDeleted: false });
    setIsRestoreOpen(false);
  };

  const handleEditRow = (record: StockingProducts): void => {
    setRecordToEdit(record);
    setIsEditOpen(true);
  };

  const handleClose = (): void => {
    setIsEditOpen(false);
    editWarehouse.resetForm();
  };

  const handleShowAuditHistory = async (productNumber: string, warehouseId: string): Promise<void> => {
    setIsAuditOpen(true);
    if (!stockingLevelId) return message.error('An error accured finding this product id, the team has been notified');
    const resp = await getAuditHistory({ inventoryStockingLevelId: stockingLevelId, productNumber, warehouseId }).unwrap();

    setAuditHistory(resp);
  };

  const handleCloseModal = (): void => {
    setIsAuditOpen(false);
    setAuditHistory(undefined);
  };

  const cols: ColumnsType<StockingProducts> = [
    {
      title: 'Product Number',
      dataIndex: 'productNumber',
      key: 'productNumber',
      sorter: (a, b) => a.productNumber.length - b.productNumber.length,
      sortDirections: ['descend', 'ascend']
    },
    {
      title: 'Warehouse',
      dataIndex: 'warehouseId',
      key: 'warehouseId',
      sorter: (a, b) => a.warehouseId.length - b.warehouseId.length,
      sortDirections: ['descend', 'ascend']
    },
    {
      title: 'Manufacturer',
      dataIndex: 'manufacturer',
      key: 'manufacturer'
    },
    {
      title: 'Category',
      dataIndex: 'category',
      key: 'category'
    },
    {
      title: 'Minimum',
      dataIndex: 'minimumQuantity',
      key: 'minimumQuantity'
    },
    {
      title: 'Maximum',
      dataIndex: 'maximumQuantity',
      key: 'maximumQuantity'
    },
    {
      title: 'Reason Code',
      dataIndex: 'reasonCode',
      key: 'reasonCode'
    },
    {
      title: 'Notes',
      dataIndex: 'notes',
      key: 'notes'
    },
    {
      title: 'Actions',
      width: 150,
      align: 'center',
      render: (_: string, record: StockingProducts, i: number): JSX.Element => {
        if (!record.isDeleted) {
          return (
            <Row justify="center" gutter={[1, 1]}>
              <Col>
                <Tooltip title="Edit Level">
                  <Button size="small" onClick={(): void => handleEditRow(record)} icon={<EditOutlined />} />
                </Tooltip>
              </Col>
              <Col>
                <Tooltip title="Delete Level">
                  <Button
                    size="small"
                    onClick={(): void => {
                      setRecordToEdit(record);
                      setIsDeleteModalOpen(true);
                    }}
                    icon={<DeleteOutlined />}
                  />
                  <Modal open={isDeleteModalOpen} onOk={handleDeleteRow} onCancel={(): void => setIsDeleteModalOpen(false)}>
                    <Row style={{ marginTop: 15 }}>
                      <Typography.Text>Are you sure you wish to delete this stocking level? The changes will not go into effect until you click save</Typography.Text>
                    </Row>
                  </Modal>
                </Tooltip>
              </Col>
              <Col>
                <Tooltip title="Audit History">
                  <Button size="small" onClick={(): Promise<void> => handleShowAuditHistory(record.productNumber, record.warehouseId)} icon={<UnorderedListOutlined />} />
                </Tooltip>
                <Modal
                  title={`Audit History for ${record.productNumber} | ${record.warehouseId}`}
                  onCancel={handleCloseModal}
                  cancelButtonProps={{ style: { display: 'none' } }}
                  onOk={handleCloseModal}
                  open={isAuditOpen}>
                  <List
                    loading={{ spinning: isLoading || isFetching, indicator: <Loader /> }}
                    dataSource={auditHistory?.messages}
                    renderItem={(item): JSX.Element => (
                      <Row style={{ marginBottom: 8 }}>
                        <Typography.Text>{item.message}</Typography.Text>
                      </Row>
                    )}
                  />
                </Modal>
              </Col>
            </Row>
          );
        }

        return (
          <Row justify="center">
            <Col>
              <Tooltip title="Restore Level">
                <Button
                  size="small"
                  onClick={(): void => {
                    setRecordToEdit(record);
                    setIsRestoreOpen(true);
                  }}
                  icon={<ToTopOutlined />}
                />
                <Modal onOk={handleRestoreRow} open={isRestoreOpen}>
                  <Typography.Text>Are you sure you wish to restore this stocking level? The changes will not go into effect until you click save</Typography.Text>
                </Modal>
              </Tooltip>
            </Col>
            <Col>
              <Tooltip title="Audit History">
                <Button size="small" onClick={(): Promise<void> => handleShowAuditHistory(record.productNumber, record.warehouseId)} icon={<UnorderedListOutlined />} />
              </Tooltip>
              <Modal
                title={`Audit History for ${record.productNumber} | ${record.warehouseId}`}
                onCancel={handleCloseModal}
                cancelButtonProps={{ style: { display: 'none' } }}
                onOk={handleCloseModal}
                open={isAuditOpen}>
                <List
                  loading={{ spinning: isLoading || isFetching, indicator: <Loader /> }}
                  dataSource={auditHistory?.messages}
                  renderItem={(item): JSX.Element => (
                    <Row style={{ marginBottom: 8 }}>
                      <Typography.Text>{item.message}</Typography.Text>
                    </Row>
                  )}
                />
              </Modal>
            </Col>
          </Row>
        );
      }
    }
  ];

  return (
    <Card
      title={
        <Space size={15}>
          <Typography.Title level={4} style={{ fontWeight: 400, padding: 0, margin: 0 }}>
            Inventory Stocking Levels
          </Typography.Title>
        </Space>
      }
      headStyle={{ background: '#b0b2bc' }}
      style={{ height: '100%' }}
      extra={
        <Space>
          <ProductsSearchModal />
          <ImportProducts />
          <ExportProductsButton dataToExport={filteredData} />
        </Space>
      }>
      <Modal width={400} onCancel={handleClose} onOk={(): Promise<void> => editWarehouse.submitForm()} title={`Edit ${record?.productNumber} | ${record?.warehouseId}`} open={isEditOpen}>
        <FormikProvider value={editWarehouse}>
          <Form>
            <Space align="start" direction="vertical" size={20} style={{ width: '100%' }}>
              <Row gutter={[16, 16]}>
                <Col span={24}>
                  <FieldInputNumber fieldName="minimumQuantity" label="Minimum" />
                </Col>
                <Col span={24}>
                  <FieldInputNumber fieldName="maximumQuantity" label="Maximum" />
                </Col>
                <SelectInput value={editWarehouse?.values?.reasonCode} options={reasonCodeOptions.map((option) => ({ label: option, value: option }))} fieldName="reasonCode" label="Reason Code" />
                <Col span={24}>
                  <FieldInputTextArea fieldName="notes" label="Notes" />
                </Col>
              </Row>
            </Space>
          </Form>
        </FormikProvider>
      </Modal>
      <StockingLevelTableFilters />
      <Table
        columns={cols}
        pagination={{ defaultPageSize: 50, total: productsTableData.length, pageSizeOptions: ['50', '150', '500'], hideOnSinglePage: true }}
        dataSource={filteredData}
        rowKey={(record): string => `${record.productNumber} - ${record.warehouseId}`}
      />
    </Card>
  );
};
