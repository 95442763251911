import { Space } from 'antd';
import { EditPageCards } from 'components/organisms/EditPageCards';

export const Home = (): JSX.Element => {
  return (
    <Space style={{ width: '100%', paddingBottom: 100 }} size={6} direction="vertical">
      {/* <HomeControls /> */}
      <EditPageCards />
    </Space>
  );
};
