import { WalletOutlined } from '@ant-design/icons';
import { Button, List, message, Typography } from 'antd';
import { MessageType } from 'antd/lib/message';
import { useFormikContext } from 'formik';
import { InventoryStockingLevelsPayload } from 'models/InventoryStockingLevelsModel';
import { FC, useState } from 'react';
import Lottie from 'react-lottie-player';
import deleteLottie from '../../common/assets/delete-lottie.json';

interface Props {
  isAdmin?: boolean;
}

export const ReasonCodeList: FC<Props> = ({ isAdmin }) => {
  const { values, setFieldValue } = useFormikContext<InventoryStockingLevelsPayload>();
  const [hover, setHover] = useState<string | null>();

  const handleRemove = (value: string): void | MessageType => {
    if (values?.products?.some((prod) => prod.reasonCode === value)) return message.error('Reason code is currently being used in products table');
    setFieldValue(
      'reasonCodes' as keyof InventoryStockingLevelsPayload,
      values?.reasonCodes?.filter((reasonCode) => reasonCode !== value)
    );
  };

  return (
    <List
      bordered
      style={{ marginTop: 8 }}
      dataSource={values.reasonCodes}
      renderItem={(reasonCode): JSX.Element => (
        <List.Item
          style={{ padding: 5 }}
          actions={
            isAdmin
              ? [
                  <Button
                    onClick={(): void | MessageType => handleRemove(reasonCode)}
                    key={'delete'}
                    className="quote-card__menu-item-delete"
                    style={{ borderRadius: '50%', borderColor: '#dc3545' }}
                    icon={
                      <Lottie
                        onMouseEnter={(): void => setHover('delete')}
                        onMouseLeave={(): void => setHover(null)}
                        animationData={deleteLottie}
                        loop={false}
                        goTo={!hover ? 0 : undefined}
                        play={hover === 'delete'}
                        style={{ width: 25, height: 25, display: 'inline-block', transform: 'scale(1.25)' }}
                      />
                    }
                  />
                ]
              : undefined
          }>
          <WalletOutlined style={{ fontSize: 18, marginRight: 8 }} />
          <Typography.Text style={{ flex: 1 }}>{reasonCode}</Typography.Text>
        </List.Item>
      )}
    />
  );
};
