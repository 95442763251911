import { DeleteOutlined, EditOutlined, WarningFilled } from '@ant-design/icons';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { Button, Card, Col, Modal, Row, Space, Spin, Table, Tooltip, Typography, message } from 'antd';
import { Loader } from 'components/atoms/Loader';
import { SelectInput } from 'components/atoms/SelectInput';
import { Form, FormikProvider, useField, useFormik, useFormikContext } from 'formik';
import { useOCD } from 'hooks/useOCD';
import _ from 'lodash';
import { AvailableStockingDimension } from 'pages/availableStockingDimensions/EditAvailableStockingDimension';
import { useState } from 'react';
import { useGetDivisionQuery } from 'redux/services/abbyCadabby/abbyCadabbyApi';
import { useAppSelector } from 'redux/store';
import * as yup from 'yup';

export const ocdValidationSchema = yup.object({
  ownerId: yup.string().label('Owner').required(),
  conditionId: yup.string().label('Condition').required(),
  dispositionId: yup.string().label('Disposition').required()
});

export const AvailableStockingDimensionCombinationsTable = (): JSX.Element => {
  const { values, setValues } = useFormikContext<AvailableStockingDimension>();

  const { ownerOptions, conditionOptions, dispositionOptions } = useOCD();

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isEditOpen, setIsEditOpen] = useState<boolean>(false);
  const [index, setIndex] = useState<number>(0);
  const [modal, contextHolder] = Modal.useModal();

  const { acuityContext } = useAppSelector((state) => state.app);
  const [{ value: record }, , { setValue: setRecord }] = useField(`dimensions[${index}]`);

  const { data: divisionData, isLoading } = useGetDivisionQuery(acuityContext?.selectedCustomer.id ?? skipToken);

  const addCombination = useFormik({
    validationSchema: ocdValidationSchema,
    enableReinitialize: true,
    initialValues: {
      ownerId: '',
      conditionId: '',
      dispositionId: ''
    },
    onSubmit: (newValues) => {
      const duplicateCheck = values.dimensions.some(
        ({ ownerId, conditionId, dispositionId }) => `${ownerId} ${conditionId} ${dispositionId}` === `${newValues.ownerId} ${newValues.conditionId} ${newValues.dispositionId}`
      );

      if (duplicateCheck) {
        message.error(`${newValues.ownerId} - ${newValues.conditionId} - ${newValues.dispositionId} already exists.`);

        return;
      }

      setValues((prev) => ({
        ...prev,
        dimensions: [...values.dimensions, newValues]
      }));
      setIsOpen(false);
      addCombination.resetForm();
    }
  });
  const editCombination = useFormik({
    enableReinitialize: true,
    initialValues: {
      ownerId: record?.ownerId ?? '',
      conditionId: record?.conditionId ?? '',
      dispositionId: record?.dispositionId ?? ''
    },
    onSubmit: (newValues) => {
      setRecord({ ...record, ...newValues });
      setIsEditOpen(false);
      editCombination.resetForm();
    }
  });

  const handleDeleteRow = (record: typeof values.dimensions[0]): void => {
    if (!values.dimensions) return;

    const recordToDelete = `${record.ownerId} - ${record.conditionId} - ${record.dispositionId}`;

    setValues((prev) => ({
      ...prev,
      dimensions: values.dimensions.filter(({ ownerId, conditionId, dispositionId }) => `${ownerId} - ${conditionId} - ${dispositionId}` !== recordToDelete)
    }));
  };

  const handleEditRow = (record: any, index: number): void => {
    setIndex(index);
    setIsEditOpen(true);
  };

  const handleClose = (): void => {
    setIsOpen(false);
    setIsEditOpen(false);
    addCombination.resetForm();
    editCombination.resetForm();
  };

  const cols = [
    {
      title: 'Owner',
      dataIndex: 'ownerId',
      key: 'ownerId'
    },
    {
      title: 'Condition',
      dataIndex: 'conditionId',
      key: 'conditionId'
    },
    {
      title: 'Disposition',
      dataIndex: 'dispositionId',
      key: 'dispositionId'
    },
    {
      title: 'Actions',
      width: 150,
      align: 'center' as any,
      render: (_: string, record: typeof values.dimensions[0], i: number) => (
        <Row justify="center" gutter={[1, 1]}>
          <Col>
            <Tooltip title="Edit Combination">
              <Button size="small" onClick={(): void => handleEditRow(record, i)} icon={<EditOutlined />} />
            </Tooltip>
          </Col>
          <Col>
            {contextHolder}
            <Tooltip title="Delete Combination">
              <Button
                size="small"
                onClick={(): void => {
                  modal.confirm({
                    title: 'IF YOU CONTINUE, THE CHANGES WILL NOT GO INTO EFFECT UNTIL YOU CLICK SAVE.',
                    icon: <WarningFilled style={{ color: 'red' }} />,
                    okButtonProps: { style: { background: 'red', border: 'none' } },
                    okText: 'Delete',
                    onOk: () => handleDeleteRow(record),
                    prefixCls: 'ant-modal'
                  });
                }}
                icon={<DeleteOutlined />}
              />
            </Tooltip>
          </Col>
        </Row>
      )
    }
  ];

  return (
    <Card
      title={
        <Typography.Title level={4} style={{ fontWeight: 400, padding: 0, margin: 0 }}>
          Available Combinations
        </Typography.Title>
      }
      headStyle={{ background: '#b0b2bc' }}
      style={{ height: '100%' }}
      extra={<Button onClick={(): void => setIsOpen(true)}>Add</Button>}>
      <Modal
        okButtonProps={{ disabled: !Object.values(addCombination.touched).length || !addCombination.isValid }}
        onCancel={handleClose}
        width={400}
        title="Add Combination"
        onOk={(): Promise<void> => addCombination.submitForm()}
        open={isOpen}>
        <FormikProvider value={addCombination}>
          <Spin spinning={isLoading} indicator={<Loader />}>
            <Form>
              <Space align="start" direction="vertical" size={20} style={{ width: '100%' }}>
                <Row gutter={[0, 16]}>
                  <SelectInput fieldName="ownerId" label="Owner" options={divisionData?.assignedOwners.map((owner) => ({ value: owner, label: owner }))} />
                  <SelectInput fieldName="conditionId" label="Condition" options={conditionOptions} />
                  <SelectInput fieldName="dispositionId" label="Disposition" options={dispositionOptions} />
                </Row>
              </Space>
            </Form>
          </Spin>
        </FormikProvider>
      </Modal>
      <Modal
        okButtonProps={{ disabled: _.isEqual(editCombination.values, editCombination.initialValues) }}
        width={400}
        onCancel={handleClose}
        onOk={(): Promise<void> => editCombination.submitForm()}
        title="Edit Combination"
        open={isEditOpen}>
        <FormikProvider value={editCombination}>
          <Spin spinning={isLoading} indicator={<Loader />}>
            <Form>
              <Space align="start" direction="vertical" size={20} style={{ width: '100%' }}>
                <Row gutter={[0, 16]}>
                  <SelectInput fieldName="ownerId" label="Owner" options={divisionData?.assignedOwners.map((owner) => ({ value: owner, label: owner }))} />
                  <SelectInput fieldName="conditionId" label="Condition" options={conditionOptions} />
                  <SelectInput fieldName="dispositionId" label="Disposition" options={dispositionOptions} />
                </Row>
              </Space>
            </Form>
          </Spin>
        </FormikProvider>
      </Modal>
      <Table columns={cols} pagination={false} dataSource={values.dimensions} rowKey={(record, i): string => `${record.ownerId} - ${record.conditionId} - ${record.dispositionId}`} />
    </Card>
  );
};
