import { Row } from 'antd';
import { useGetPermissions } from 'hooks/useAuthentication';
import { AvailableStockingDimensions } from './AvailableStockingDimensions';
import { ReplenishmentDimensions } from './ReplenishmentDimensions';
import { StockingLevels } from './StockingLevels';
import { StockingLevelWarehouseTable } from './StockingLevelWarehouseTable';

export const EditPageCards: React.FC = (): JSX.Element => {
  const { userRole } = useGetPermissions();

  return (
    <>
      <Row gutter={4}>
        {userRole === 'admin' && (
          <>
            <AvailableStockingDimensions />
            <ReplenishmentDimensions />
            <StockingLevelWarehouseTable />
          </>
        )}
        <StockingLevels />
      </Row>
    </>
  );
};
