import { DeleteOutlined, EditOutlined, HolderOutlined, WarningFilled } from '@ant-design/icons';
import { Button, Card, Col, Modal, Row, Space, Spin, Table, Tooltip, Typography, message } from 'antd';
import { Loader } from 'components/atoms/Loader';
import { SelectInput } from 'components/atoms/SelectInput';
import { Form, FormikProvider, FormikValues, useField, useFormik, useFormikContext } from 'formik';
import { useWarehouses } from 'hooks/useWarehouses';
import update from 'immutability-helper';
import { useCallback, useRef, useState } from 'react';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { manager } from 'utils/dndUtils';
import * as yup from 'yup';

const validationSchema = yup.object({
  warehouseId: yup.string().label('Warehouse').required()
});

interface DraggableBodyRowProps extends React.HTMLAttributes<HTMLTableRowElement> {
  index: number;
  moveRow: (dragIndex: number, hoverIndex: number) => void;
}

export const ReplenishmentDimensionWarehouseTable = (): JSX.Element => {
  const { values, setValues } = useFormikContext<FormikValues>();
  const [{ value }, , { setValue }] = useField('warehouses');

  const { warehouseOptions, isLoading } = useWarehouses();

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isEditOpen, setIsEditOpen] = useState<boolean>(false);
  const [index, setIndex] = useState<number>(0);
  const [modal, contextHolder] = Modal.useModal();

  const [{ value: record }, , { setValue: setRecord }] = useField(`warehouses[${index}]`);

  const type = 'DraggableBodyRow';

  const addWarehouse = useFormik({
    validationSchema,
    enableReinitialize: true,
    initialValues: {
      warehouseId: '',
      priority: value.length + 1
    },
    onSubmit: (newValues) => {
      const duplicateCheck = values.warehouses.some(({ warehouseId }: { warehouseId: string }) => warehouseId === newValues.warehouseId);

      if (duplicateCheck) {
        message.error(`${newValues.warehouseId} already exists.`);

        return;
      }
      setValue([...value, newValues]);
      setIsOpen(false);
      addWarehouse.resetForm();
    }
  });
  const editWarehouse = useFormik({
    enableReinitialize: true,
    initialValues: {
      warehouseId: record?.warehouseId ?? '',
      priority: record?.priority ?? 0
    },
    onSubmit: (newValues) => {
      setRecord({ ...record, ...newValues });
      setIsEditOpen(false);
      editWarehouse.resetForm();
    }
  });

  const handleDeleteRow = (record: any): void => {
    if (!values.warehouses) return;

    setValues((prev: any) => ({
      ...prev,
      warehouses: values.warehouses ? values.warehouses.filter(({ warehouseId }: any) => warehouseId !== record.warehouseId).map((item: any, i: number) => ({ ...item, priority: i + 1 })) : undefined
    }));
  };

  const handleEditRow = (record: any, index: number): void => {
    setIndex(index);
    setIsEditOpen(true);
  };

  const handleClose = (): void => {
    setIsOpen(false);
    setIsEditOpen(false);
    addWarehouse.resetForm();
    editWarehouse.resetForm();
  };

  const DraggableBodyRow = ({ index, moveRow, className, style, ...restProps }: DraggableBodyRowProps): JSX.Element => {
    const ref = useRef<HTMLTableRowElement>(null);

    const [{ isOver, dropClassName }, drop] = useDrop({
      accept: type,
      collect: (monitor) => {
        const { index: dragIndex } = monitor.getItem() || {};

        if (dragIndex === index) {
          return {};
        }

        return {
          isOver: monitor.isOver(),
          dropClassName: dragIndex < index ? ' drop-over-downward' : ' drop-over-upward'
        };
      },
      drop: (item: { index: number }) => {
        moveRow(item.index, index);
      }
    });
    const [, drag] = useDrag({
      type,
      item: { index },
      collect: (monitor) => ({
        isDragging: monitor.isDragging()
      })
    });

    drop(drag(ref));

    return <tr ref={ref} className={`${className}${isOver ? dropClassName : ''}`} style={{ cursor: 'move', ...style }} {...restProps} />;
  };
  const cols = [
    {
      dataIndex: 'sort',
      width: 30,
      render: () => <HolderOutlined style={{ cursor: 'grab' }} />
    },
    {
      title: 'Priority',
      dataIndex: 'priority',
      key: 'priority'
    },
    {
      title: 'Warehouse',
      dataIndex: 'warehouseId',
      key: 'warehouseId'
    },
    {
      title: 'Actions',
      width: 150,
      align: 'center' as any,
      render: (_: string, record: any, i: number) => (
        <Row justify="center" gutter={[1, 1]}>
          <Col>
            <Tooltip title="Edit Warehouse">
              <Button size="small" onClick={(): void => handleEditRow(record, i)} icon={<EditOutlined />} />
            </Tooltip>
          </Col>
          <Col>
            {contextHolder}
            <Tooltip title="Delete Warehouse">
              <Button
                size="small"
                onClick={(): void => {
                  modal.confirm({
                    title: 'IF YOU CONTINUE, THE CHANGES WILL NOT GO INTO EFFECT UNTIL YOU CLICK SAVE.',
                    icon: <WarningFilled style={{ color: 'red' }} />,
                    okButtonProps: { style: { background: 'red', border: 'none' } },
                    okText: 'Delete',
                    onOk: () => handleDeleteRow(record),
                    prefixCls: 'ant-modal'
                  });
                }}
                icon={<DeleteOutlined />}
              />
            </Tooltip>
          </Col>
        </Row>
      )
    }
  ];
  const moveRow = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      if (!value) return;
      const dragRow = value[dragIndex];

      setValue(
        update(value, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragRow]
          ]
        }).map((d: any, i: number) => ({ ...d, priority: i + 1 }))
      );
    },
    [setValue, value]
  );

  const components = {
    body: {
      row: DraggableBodyRow
    }
  };

  return (
    <Card
      title={
        <Typography.Title level={4} style={{ fontWeight: 400, padding: 0, margin: 0 }}>
          Replenishment Warehouses
        </Typography.Title>
      }
      headStyle={{ background: '#18a79984' }}
      style={{ height: '100%' }}
      extra={<Button onClick={(): void => setIsOpen(true)}>Add</Button>}>
      {/* <FormikProvider value={warehouseFormik}> */}
      <Modal
        okButtonProps={{ disabled: !Object.values(addWarehouse.touched).length || !addWarehouse.isValid }}
        onCancel={handleClose}
        width={400}
        title="Add Warehouse"
        onOk={(): Promise<void> => addWarehouse.submitForm()}
        open={isOpen}>
        <FormikProvider value={addWarehouse}>
          <Form>
            <Space align="start" direction="vertical" size={20} style={{ width: '100%' }}>
              <SelectInput fieldName="warehouseId" label="Warehouse" options={warehouseOptions} />
            </Space>
          </Form>
        </FormikProvider>
      </Modal>
      <Modal
        okButtonProps={
          {
            // disabled: _.isEqual(editWarehouseFormik.initialValues, editWarehouseFormik.values)
          }
        }
        width={400}
        onCancel={handleClose}
        onOk={(): Promise<void> => editWarehouse.submitForm()}
        title="Edit Warehouse"
        open={isEditOpen}>
        <FormikProvider value={editWarehouse}>
          <Form>
            <Space align="start" direction="vertical" size={20} style={{ width: '100%' }}>
              <Spin spinning={isLoading} indicator={<Loader />}>
                <SelectInput fieldName="warehouseId" label="Warehouse" options={warehouseOptions} />
              </Spin>
            </Space>
          </Form>
        </FormikProvider>
      </Modal>
      <DndProvider manager={manager} context={window} backend={HTML5Backend}>
        <Table
          columns={cols}
          pagination={false}
          dataSource={values.warehouses}
          components={components}
          rowKey={(record, i): string => `${record.warehouseId} - ${record.priority}`}
          onRow={(_, index): any => {
            const attr = {
              index,
              moveRow
            };

            return attr as React.HTMLAttributes<any>;
          }}
        />
      </DndProvider>
    </Card>
  );
};
