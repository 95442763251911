import { CheckOutlined } from '@ant-design/icons';
import { Button, Card, Col, Divider, Row } from 'antd';
import { InlineStylesModel } from 'models/InlineStylesModel';
import { WarehousePriorityModel } from 'models/WarehousePriorityModel';
import { useDispatch, useSelector } from 'react-redux';
import { addWarehousePriority, removeWarehousePriority } from 'redux/slices/selectedSotckingLevelsSlice';
import { ReduxState } from 'redux/store';
import EllipsesText from './EllipsiesText';

const styles: InlineStylesModel = {
  card: {
    backgroundColor: 'white',
    borderRadius: 5,
    margin: '6px'
  },
  categoryId: {
    color: 'grey',
    lineHeight: '.7',
    paddingBottom: '13px'
  },
  manufacturer: {
    fontWeight: 600
  },
  dispositionRuleType: {
    color: 'grey',
    height: '15px'
  },
  partDescription: {
    color: 'grey'
  },
  itemName: {
    fontWeight: 600,
    fontSize: 20,
    color: '#203175'
  },
  salesOrderType: {
    color: 'grey',
    textAlign: 'right'
  },
  robotPadding: {
    paddingTop: 5,
    cursor: 'pointer'
  },
  smallGrouping: {
    color: 'grey',
    fontSize: '8',
    background: '#8383831a',
    padding: 4,
    borderRadius: 100
  },
  view: {
    color: '#203175',
    fontWeight: 600,
    fontSize: '15px',
    cursor: 'pointer'
  },
  test: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis'
  }
};

interface Props {
  item: WarehousePriorityModel;
}

export const RecycleCard: React.FC<Props> = ({ item }: Props) => {
  const { selectedWarehousePriorities } = useSelector((state: ReduxState) => state.selectedStockingLevels);
  const dispatch = useDispatch();

  const isSelected = selectedWarehousePriorities.some((selectedItem: any) => selectedItem.id === item.id);

  const handleToggleSelectProduct = (): void => {
    if (isSelected) {
      dispatch(removeWarehousePriority(item));
    } else {
      dispatch(addWarehousePriority(item));
    }
  };

  return (
    <Card style={styles.card}>
      <Row gutter={[2, 0]} justify="space-between" align="middle">
        <Col style={styles.itemName} span={22}>
          <EllipsesText tooltipCharacterCount={23} text={item.name} />
        </Col>
        <Col style={styles.itemName} span={2}>
          <Button style={{ borderRadius: '50%' }} onClick={handleToggleSelectProduct} type={isSelected ? 'primary' : 'default'} icon={isSelected ? <CheckOutlined /> : ' '} />
        </Col>
      </Row>
      <Row justify="space-between" align="top"></Row>
      <Row style={styles.dispositionRuleType}>{item.description}</Row>
      <Row justify="center">
        <Col span={24} style={{ marginBottom: 10 }}>
          <Divider />
        </Col>
      </Row>
    </Card>
  );
};
