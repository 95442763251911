import { Space } from 'antd';
import { ChangeEvent, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setInventoryStockingNameContains, setInventoryStockingOffset } from 'redux/services/sonnyFriendly/inventoryStockingParams';
import { useDebouncedCallback } from 'use-debounce';
import { FancyInput } from '../FancyInput';

export const InventoryStockingNameContainsFilter = (): JSX.Element => {
  const dispatch = useDispatch();

  /* ****************** Functions ****************** */
  // Handle delay of searching data
  const handleInputChange = useDebouncedCallback((ev: ChangeEvent<HTMLInputElement>) => {
    dispatch(setInventoryStockingOffset(0));
    dispatch(setInventoryStockingNameContains(ev.target.value));
  }, 500);

  useEffect(() => {
    return (): void => {
      dispatch(setInventoryStockingNameContains(undefined));
      dispatch(setInventoryStockingOffset(0));
    };
  }, [dispatch]);

  /* ****************** Render ****************** */

  return (
    <Space direction="vertical" size="small" style={{ marginLeft: 5, marginRight: 5 }}>
      <FancyInput placeholder={'Stocking Level Name'} onChange={handleInputChange} width={200} />
    </Space>
  );
};
