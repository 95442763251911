import { skipToken } from '@reduxjs/toolkit/dist/query';
import { Card, Col, message, Row, Space, Spin, Typography } from 'antd';
import { BlockLoader } from 'components/atoms/BlockLoader';
import { FieldInput } from 'components/atoms/FieldInput';
import { FieldInputSelect } from 'components/atoms/FieldInputSelect';
import { Loader } from 'components/atoms/Loader';
import { EditPageControls } from 'components/molecules/EditPageControls';
import { ReplenishmentDimensionCombinationsTable } from 'components/organisms/ReplenishmentDimensionCombinationsTable';
import { ReplenishmentDimensionWarehouseTable } from 'components/organisms/ReplenishmentDimensionWarehouseTable';
import { FormikProvider, useFormik } from 'formik';
import _ from 'lodash';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDeleteReplenishmentDimensionMutation, useGetReplenishmentDimensionQuery, useUpdateReplenishmentDimensionMutation } from 'redux/services/sonnyFriendly/sonnyFriendly';
import { replenishmentDimensionsSchema } from './CreateReplenishmentDimension';

export interface ReplenishmentDimension {
  name: string;
  replenishmentType: string;
  description: string;
  dimensions: { ownerId: string; conditionId: string; dispositionId: string; priority: number }[];
  warehouses: { warehouseId: string; priority: number }[];
  exit: boolean;
}

const GeneralSection: React.FC = () => {
  return (
    <Card
      style={{ height: '100%' }}
      headStyle={{ background: 'rgb(206, 213, 242)' }}
      title={
        <Typography.Title level={4} style={{ fontWeight: 400, fontSize: 22 }}>
          General
        </Typography.Title>
      }>
      <Row gutter={[30, 10]}>
        <Col span={12}>
          <Space direction="vertical" style={{ width: '100%' }} size={15}>
            <FieldInput fieldName="name" label="Name" />
          </Space>
        </Col>
        <Col span={12}>
          <Space direction="vertical" style={{ width: '100%' }} size={15}>
            <FieldInput fieldName="description" label="Description" />
          </Space>
        </Col>
        <Col span={12}>
          <Space direction="vertical" style={{ width: '100%' }} size={15}>
            <FieldInputSelect options={['Test Order', 'Purchase']} fieldName="replenishmentType" label="Replenishment Type" />
          </Space>
        </Col>
      </Row>
    </Card>
  );
};

export const EditReplenishmentDimension: React.FC = () => {
  const { replenishmentDimensionId } = useParams();
  const nav = useNavigate();
  const [deleteReplenishmentDimension, { isLoading: isDeleting }] = useDeleteReplenishmentDimensionMutation();

  const { data, isLoading } = useGetReplenishmentDimensionQuery(replenishmentDimensionId ?? skipToken, { skip: !replenishmentDimensionId });
  const [updateReplenishmentDimension, { isLoading: isUpdating }] = useUpdateReplenishmentDimensionMutation();

  const navigate = useNavigate();

  const formik = useFormik<ReplenishmentDimension>({
    validationSchema: replenishmentDimensionsSchema,
    enableReinitialize: true,
    initialValues: {
      name: data?.name ?? '',
      replenishmentType: data?.replenishmentType ?? '',
      description: data?.description ?? '',
      dimensions: data?.dimensions ?? [],
      warehouses: data?.warehouses ?? [],
      exit: false
    },
    onSubmit: async (values) => {
      const { exit, ...payload } = values;

      try {
        await updateReplenishmentDimension({ replenishmentDimensionId: replenishmentDimensionId ?? '', payload: { ...payload, isActive: true } });
        message.success(`${values.name} successfully updated!`);
        if (exit) {
          nav('/');
        }
      } catch (e) {
        console.log(e, 'error');
        message.error((e as { data: { errorMessage: string } })?.data?.errorMessage || 'An error occurred while submitting the form. Please try again.');
        formik.setValues({ ...formik.values, exit: false });
      }
    }
  });

  const handleDelete = async (): Promise<void> => {
    if (!replenishmentDimensionId) return message.error('An error occured (no id) the team has been notified');
    try {
      await deleteReplenishmentDimension(replenishmentDimensionId);

      navigate('/');
      message.success(`Replenishment dimension was succesfully deleted`);
    } catch (err) {
      console.log(err);
      message.error((err as { data: { errorMessage: string } }).data.errorMessage);
    }
  };

  const isValid = formik.isValid && !_.isEqual(formik.values, formik.initialValues);

  if (isLoading) return <BlockLoader direction="loader loader--slideUp" />;

  return (
    <FormikProvider value={formik}>
      <Spin spinning={isUpdating || isDeleting} indicator={<Loader message={isUpdating ? 'Updating replenishment dimension' : isDeleting ? 'Deleting replenishment dimension' : ''} />}>
        <Space direction="vertical" style={{ width: '100%', paddingBottom: 100 }}>
          <EditPageControls
            title="Edit Replenishment Dimension"
            description="replenishment dimension"
            submitButtonProps={{ handleSubmit: () => formik.submitForm(), disabled: !isValid }}
            deleteButtonProps={{ handleDelete }}
          />
          <GeneralSection />
          <ReplenishmentDimensionCombinationsTable />
          <ReplenishmentDimensionWarehouseTable />
        </Space>
      </Spin>
    </FormikProvider>
  );
};
