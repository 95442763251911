import { DeleteOutlined } from '@ant-design/icons';
import { Button, Col, Divider, Row, Space, Tooltip, Typography } from 'antd';
import { CaretSpinIcon } from 'components/atoms/CaretSpinIcon';
import { ReduxServiceFilter } from 'components/atoms/filters/ReduxServiceFilter';
import { LegacyRef, useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useNavigate } from 'react-router-dom';
import { animated, useSpring } from 'react-spring';
import { useMeasure } from 'react-use';
import { setWarehousePriorityDescriptionContains, setWarehousePriorityNameContains } from 'redux/services/sonnyFriendly/warehousePriorityParams';
import { WarehousePriorityTable } from './WarehousePriorityTable';

export const testTableData = [
  { warehouse: 'CHO-NYC', group: 'NORTHEAST', priority: 1, primaryReplenishmentWarehouse: 'MDSI-NYC1' },
  { warehouse: 'CHO-ATL', group: 'SOUTHEAST', priority: 2, primaryReplenishmentWarehouse: 'MDSI-ATL1' },
  { warehouse: 'CHO-LAX', group: 'WEST', priority: 3, primaryReplenishmentWarehouse: 'MDSI-LAX1' }
];

export const StockingLevelWarehouseTable = (): JSX.Element => {
  const [showRequestTypes, setShowRequestTypes] = useState(false);
  const navigate = useNavigate();

  const [ref, { height }] = useMeasure();

  const expandAnimation = useSpring({
    config: { friction: 12, bounce: 0 },
    height: showRequestTypes ? height : 0
  });

  const handleAdd = (): void => {
    navigate('/warehouse-priority/create');
  };

  const handleToggleShowRequestTypes = (ev: React.MouseEvent<HTMLElement, MouseEvent>): void => {
    ev.preventDefault();
    ev.stopPropagation();
    setShowRequestTypes((prevState) => !prevState);
  };

  return (
    <Col span={24} style={{ marginBottom: showRequestTypes ? 10 : 0 }}>
      <Row onClick={handleToggleShowRequestTypes} style={{ padding: 15, background: '#ffe8b7c5', borderRadius: 5, cursor: 'pointer' }} justify="space-between">
        <Typography.Title style={{ fontWeight: 400, padding: 0, margin: 0, fontSize: 22 }} level={3}>
          Warehouse Priority
        </Typography.Title>
        <Space>
          {/* <Button onClick={handleAdd}>Add</Button> */}
          <Button style={{ width: 115 }} type="text" onClick={handleToggleShowRequestTypes} key="show-divisions" icon={<CaretSpinIcon isOpen={showRequestTypes} />}>
            {showRequestTypes ? 'Collapse' : 'Expand'}
          </Button>
        </Space>
      </Row>
      <Row style={{ background: 'transparent', padding: '0 1.25px' }}>
        <animated.div style={{ ...{ minHeight: 10, overflow: 'hidden', width: '100%' }, ...expandAnimation }}>
          {showRequestTypes && (
            <div ref={ref as LegacyRef<HTMLDivElement>} style={{ width: '100%' }}>
              <Col span={24}>
                <Row gutter={8} justify="space-between" style={{ marginTop: 8, background: 'white', padding: 20 }}>
                  <Row gutter={8}>
                    <Col>
                      <ReduxServiceFilter label="Warehouse" filterAction={setWarehousePriorityNameContains} />
                    </Col>
                    <Col>
                      <ReduxServiceFilter label="Description" filterAction={setWarehousePriorityDescriptionContains} />
                    </Col>
                  </Row>
                  <Col>
                    <Tooltip>
                      <Button onClick={handleAdd}>Add Warehouse</Button>
                    </Tooltip>
                    <Button onClick={(): void => navigate('/warehouse-priority/recycle-bin')} type="primary" style={{ marginLeft: 5 }} icon={<DeleteOutlined />}>
                      Recycle-Bin
                    </Button>
                  </Col>
                </Row>
              </Col>
              <Divider style={{ margin: '8px 0' }} />

              <DndProvider backend={HTML5Backend}>
                <WarehousePriorityTable />
              </DndProvider>
            </div>
          )}
        </animated.div>
      </Row>
    </Col>
  );
};
