import { Card, Col, message, Row, Space, Spin, Typography } from 'antd';
import { FieldInput } from 'components/atoms/FieldInput';
import { Loader } from 'components/atoms/Loader';
import { CreatePageControls } from 'components/molecules/CreatePageControls';
import { AvailableStockingDimensionCombinationsTable } from 'components/organisms/AvailableStockingDimensionCombinationsTable';
import { FormikProvider, useFormik } from 'formik';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useCreateAvailableStockingDimensionMutation } from 'redux/services/sonnyFriendly/sonnyFriendly';
import * as yup from 'yup';

export interface AvailableStockingDimension {
  name: string;
  description: string;
  dimensions: { ownerId: string; conditionId: string; dispositionId: string }[];
  exit: boolean;
}

export const availableStockingDimensionsSchema = yup.object({
  name: yup.string().label('Name').required(),
  description: yup.string().label('Description').required(),
  dimensions: yup.array().min(1).required()
});

const GeneralSection: React.FC = () => {
  return (
    <Card
      style={{ height: '100%' }}
      headStyle={{ background: 'rgb(206, 213, 242)' }}
      title={
        <Typography.Title level={4} style={{ fontWeight: 400, fontSize: 22 }}>
          General
        </Typography.Title>
      }>
      <Row gutter={[30, 10]}>
        <Col span={12}>
          <Space direction="vertical" style={{ width: '100%' }} size={15}>
            <FieldInput fieldName="name" label="Name" />
          </Space>
        </Col>
        <Col span={12}>
          <Space direction="vertical" style={{ width: '100%' }} size={15}>
            <FieldInput fieldName="description" label="Description" />
          </Space>
        </Col>
      </Row>
    </Card>
  );
};

export const CreateAvailableStockingDimension: React.FC = () => {
  const [createStockingDimension] = useCreateAvailableStockingDimensionMutation();
  const navigate = useNavigate();
  const formik = useFormik<AvailableStockingDimension>({
    validationSchema: availableStockingDimensionsSchema,
    enableReinitialize: true,
    initialValues: {
      name: '',
      description: '',
      dimensions: [],
      exit: false
    },
    onSubmit: async (values) => {
      const { exit, ...payload } = values;

      try {
        const response = await createStockingDimension({ payload: { ...payload, isActive: true } }).unwrap();

        message.success(`${response.name} succesfully created`);
        if (exit) {
          navigate('/');
        } else {
          navigate(`/available-stocking-dimension/${response.id}`);
        }
      } catch (err) {
        console.log(err);
        message.error((err as { data: { errorMessage: string } }).data.errorMessage);
      }
      console.log(values);
    }
  });

  return (
    <FormikProvider value={formik}>
      <Spin spinning={formik.isSubmitting} indicator={<Loader message="Creating stocking dimension" />}>
        <Space direction="vertical" style={{ width: '100%', paddingBottom: 100 }}>
          <CreatePageControls
            submitButtonProps={{
              handleSubmit: () => formik.submitForm(),
              disabled: Boolean(!formik.values.dimensions.length || !formik.values.name || !formik.values.description),
              tooltip: 'You must add one OCD combination to save'
            }}
            title="Create Stocking Dimension"
          />
          <GeneralSection />
          <AvailableStockingDimensionCombinationsTable />
        </Space>
      </Spin>
    </FormikProvider>
  );
};
