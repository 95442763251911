import { FetchBaseQueryError, skipToken } from '@reduxjs/toolkit/dist/query';
import { Card, Col, Collapse, message, Row, Space, Spin, Tooltip, Typography } from 'antd';
import CollapsePanel from 'antd/lib/collapse/CollapsePanel';
import { AvailableStockingDimensionsSelectField } from 'components/atoms/AvailableStockingDimensionsSelectField';
import { FieldInput } from 'components/atoms/FieldInput';
import { FieldInputSelect } from 'components/atoms/FieldInputSelect';
import { Loader } from 'components/atoms/Loader';
import { ReasonCodeInput } from 'components/atoms/ReasonCodeInput';
import { ReasonCodeList } from 'components/atoms/ReasonCodeList';
import { ReplenishmentDimensionsSelectField } from 'components/atoms/ReplenishmentDimensionsSelectField.tsx';
import { WarehousePrioritySelectField } from 'components/atoms/WarehousePrioritySelectField';
import { WimsicalError } from 'components/atoms/WimsicalError';
import { EditPageControls } from 'components/molecules/EditPageControls';
import { StockingInventoryLevelsTable } from 'components/organisms/StockingInventoryLevelsTable';
import { FormikProvider, useFormik } from 'formik';
import { useGetPermissions } from 'hooks/useAuthentication';
import { InventoryStockingLevelsPayload, inventoryStockingLevelsSchema } from 'models/InventoryStockingLevelsModel';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDeleteInventoryStockingLevelMutation, useGetInventoryStockingLevelQuery, useUpdateInventoryStockingLevelMutation } from 'redux/services/sonnyFriendly/sonnyFriendly';

const GeneralSection: React.FC<{ userRole: 'user' | 'admin'; values: InventoryStockingLevelsPayload }> = ({ userRole, values }) => {
  const isAdmin = userRole === 'admin';

  return (
    <Card
      style={{ height: '100%' }}
      headStyle={{ background: 'rgb(206, 213, 242)' }}
      title={
        <Typography.Title level={4} style={{ fontWeight: 400, fontSize: 22 }}>
          General
        </Typography.Title>
      }>
      <Row gutter={[30, 10]}>
        <Col span={12}>
          <Space direction="vertical" style={{ width: '100%' }} size={15}>
            <FieldInput disabled={!isAdmin} readOnly={!isAdmin} fieldName="name" label="Name" />
          </Space>
        </Col>
        <Col span={12}>
          <Space direction="vertical" style={{ width: '100%' }} size={15}>
            <FieldInput disabled={!isAdmin} readOnly={!isAdmin} fieldName="description" label="Description" />
          </Space>
        </Col>
        <Col span={12}>
          <Space direction="vertical" style={{ width: '100%' }} size={15}>
            <FieldInputSelect disabled={!isAdmin} options={['Sparing', 'Deployment']} fieldName="stockingLevelType" label="Stocking Level Type" />
          </Space>
        </Col>
        <Col span={12}>
          <Tooltip title={isAdmin && 'Changing this field will clear out all exisiting products'}>
            <Space direction="vertical" style={{ width: '100%' }} size={15}>
              <WarehousePrioritySelectField disabled={!isAdmin} fieldName="warehousePriorityId" label="Warehouse Priority" />
            </Space>
          </Tooltip>
        </Col>
        <Col span={12}>
          <Space direction="vertical" style={{ width: '100%' }} size={15}>
            <ReplenishmentDimensionsSelectField disabled={!isAdmin} fieldName="replenishmentDimensionId" label="Replenishment Dimensions" />
          </Space>
        </Col>
        <Col span={12}>
          <Space direction="vertical" style={{ width: '100%' }} size={15}>
            <AvailableStockingDimensionsSelectField disabled={!isAdmin} fieldName="availableStockDimensionId" label="Available Stocking Dimensions" />
          </Space>
        </Col>
        <Col span={12}>
          <Collapse expandIconPosition="start" bordered={false}>
            <CollapsePanel key={1} header={<Typography.Text style={{ padding: 0, margin: 0 }}>Reason Codes</Typography.Text>} style={{ background: 'white' }}>
              <Space direction="vertical" style={{ width: '100%' }} size={15}>
                <ReasonCodeInput isAdmin={!isAdmin} />
                {!!values.reasonCodes.length && <ReasonCodeList isAdmin={isAdmin} />}
              </Space>
            </CollapsePanel>
          </Collapse>
        </Col>
      </Row>
    </Card>
  );
};

export const EditStockingLevelPage: React.FC = () => {
  const { userRole } = useGetPermissions();
  const { stockingLevelId } = useParams();

  const navigate = useNavigate();

  const [deleteStockingLevel, { isLoading: isDeleting }] = useDeleteInventoryStockingLevelMutation();

  const [updateInventoryStockingLevel, { isLoading: isUpdating }] = useUpdateInventoryStockingLevelMutation();

  const { data, isLoading, isFetching, isError, error } = useGetInventoryStockingLevelQuery(stockingLevelId ?? skipToken);

  const formik = useFormik<InventoryStockingLevelsPayload>({
    enableReinitialize: true,
    validationSchema: inventoryStockingLevelsSchema,
    initialValues: {
      name: data?.name ?? '',
      description: data?.description ?? '',
      stockingLevelType: data?.stockingLevelType ?? '',
      products: data?.products ?? [],
      availableStockDimensionId: data?.availableStockDimension ? data?.availableStockDimension.id : '',
      replenishmentDimensionId: data?.replenishmentDimension ? data?.replenishmentDimension.id : '',
      warehousePriorityId: data?.warehousePriority.id || '',
      exit: false,
      reasonCodes: data?.reasonCodes ?? [],
      isDeleted: data?.isDeleted ?? false,
      isActive: data?.isActive ?? true
    },
    onSubmit: async (values) => {
      const { exit, ...payload } = values;

      if (!stockingLevelId) return message.error('No id found, please the refresh the page');

      try {
        const response = await updateInventoryStockingLevel({ stockingLevelId, payload: { ...payload } }).unwrap();

        message.success(`${response.name} has been successfully updated`);
        if (exit) {
          navigate('/');
        }
      } catch (err) {
        console.log(err, 'error');
        message.error((err as { data: { errorMessage: string } })?.data?.errorMessage || 'An error occurred while submitting the form. Please try again.');
        formik.setValues({ ...formik.values, exit: false });
      }
    }
  });

  const handleDelete = async (): Promise<void> => {
    if (!stockingLevelId) return message.error('An error occured (no id) the team has been notified');
    try {
      await deleteStockingLevel(stockingLevelId);

      message.success(`Stocking level was succesfully deleted`);
      navigate('/');
    } catch (err) {
      console.log(err);
      message.error((err as { data: { errorMessage: string } }).data.errorMessage);
    }
  };

  if (isLoading || isFetching) return <Loader />;

  if (isError) {
    const queryError = error as FetchBaseQueryError;

    return (
      <Row justify="center" style={{ width: '100%' }}>
        <Col span={24}>
          <WimsicalError status={typeof queryError.status === 'number' ? queryError.status : 500} message={''} />
        </Col>
      </Row>
    );
  }

  const isDisabled = Boolean(
    !formik.values.name ||
      !formik.values.description ||
      !formik.values.replenishmentDimensionId ||
      !formik.values.availableStockDimensionId ||
      !formik.values.warehousePriorityId ||
      !formik.values.stockingLevelType ||
      !formik.values.reasonCodes.length
  );

  return (
    <FormikProvider value={formik}>
      <Spin spinning={formik.isSubmitting || isDeleting} indicator={<Loader message={formik.isSubmitting ? 'Updating stocking level' : isDeleting ? 'Deleting stocking level' : ''} />}>
        <Space direction="vertical" style={{ width: '100%', paddingBottom: 100 }}>
          <EditPageControls
            title="Edit Stocking Level"
            description="stocking level"
            submitButtonProps={{ handleSubmit: () => formik.submitForm(), disabled: isDisabled }}
            deleteButtonProps={{ handleDelete: handleDelete }}
          />
          <GeneralSection values={formik.values} userRole={userRole} />
          <StockingInventoryLevelsTable reasonCodeOptions={formik.values.reasonCodes} />
        </Space>
      </Spin>
    </FormikProvider>
  );
};
