import { Row } from 'antd';
import TextArea, { TextAreaProps } from 'antd/lib/input/TextArea';
import { FormLabel } from 'components/UI/FormLabel';
import { Field, FieldProps } from 'formik';
import { CSSProperties, FC } from 'react';

interface Props extends TextAreaProps {
  fieldName: string;
  type?: string;
  label: string;
  labelStyle?: CSSProperties;
  readOnly?: boolean;
  disabled?: boolean;
}

export const FieldInputTextArea: FC<Props> = ({ fieldName, label, labelStyle, readOnly = false, disabled = false, ...rest }) => {
  return (
    <Field name={fieldName}>
      {({
        field, // { name, value, onChange, onBlur }
        form: { setFieldTouched }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
        meta
      }: FieldProps): JSX.Element => (
        <Row style={{ width: '100%' }}>
          <FormLabel style={labelStyle} label={label} />
          <TextArea
            readOnly={readOnly}
            disabled={disabled}
            maxLength={500}
            status={meta.error && meta.touched ? 'error' : undefined}
            onFocus={(): void => setFieldTouched(fieldName, false)}
            {...field}
            {...rest}
          />
          <p style={{ color: 'grey', marginTop: 3 }}>{meta?.value?.length}/500</p>
          <div className={meta.touched && meta.error ? 'field-error-show' : 'field-error-hide'}>{meta.error}</div>
        </Row>
      )}
    </Field>
  );
};
