import { Row, Space } from 'antd';
import { StockingLevelsActiveFilter } from 'components/atoms/StockingLevelsActiveFilter';
import { ReduxServiceFrontendFilter } from 'components/atoms/filters/ReduxServiceFrontendFilter';
import { FC } from 'react';
import { setCategoryContains, setManufacturerContains, setProductNumberContains, setWarehouseContains } from 'redux/slices/filterSlice';

export const StockingLevelTableFilters: FC = () => {
  return (
    <Row align={'middle'} style={{ padding: '0px 0px 24px 0px', background: 'white' }}>
      <Space>
        <ReduxServiceFrontendFilter label="Prodoct Number" filterAction={setProductNumberContains} />
        <ReduxServiceFrontendFilter label="Warehouse" filterAction={setWarehouseContains} />
        <ReduxServiceFrontendFilter label="Manufacturer" filterAction={setManufacturerContains} />
        <ReduxServiceFrontendFilter label="Category" filterAction={setCategoryContains} />
        <StockingLevelsActiveFilter />
      </Space>
    </Row>
  );
};
