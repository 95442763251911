import { useGetInfoQuery as useAnimalsQuery } from 'redux/services/animals/animalsApi';
import { useGetInfoQuery as useBeautifulDayMonsterQuery } from '../redux/services/beautifulDayMonster/beautifulDayMonsterApi';
import { useGetInfoQuery as useCookieMonsterQuery } from '../redux/services/cookieMonster/cookieMonsterApi';
import { useGetInfoQuery as useDonaldGrumpQuery } from '../redux/services/donaldGrump/donaldGrumpApi';
import { useGetInfoQuery as useKingstonLivingstonQuery } from '../redux/services/kingstonLivingston/useKingstonLivingstonService';
import { useGetInfoQuery as useMaggieCadabbyQuery } from '../redux/services/maggieCadabby/useMaggieCadabbyService';
import { useGetInfoQuery as useSherryNetherlandQuery } from '../redux/services/sherryNetherland/sherryNetherland';
import { useGetInfoQuery as useSonnyFriendlyQuery } from '../redux/services/sonnyFriendly/sonnyFriendly';
export const useAbout = (): { data: any[] } => {
  const { data: getAnimalsInfo, isLoading: isAnimalsInfoLoading } = useAnimalsQuery();
  const { data: getBeautifulDayMonsterInfo, isLoading: isBeautifulDayMonsterInfoLoading } = useBeautifulDayMonsterQuery();
  const { data: getCookieMonsterInfo, isLoading: isCookieMonsterInfoLoading } = useCookieMonsterQuery();
  const { data: getDonaldGrumpInfo, isLoading: isDonaldGrumpInfoLoading } = useDonaldGrumpQuery();
  const { data: getKingstonLivingstonInfo, isLoading: isKingstonLivingstonInfoLoading } = useKingstonLivingstonQuery();
  const { data: getMaggieCadabbyInfo, isLoading: isMaggieCadabbyInfoLoading } = useMaggieCadabbyQuery();
  const { data: getSherryNetherlandInfo, isLoading: isSherryNetherlandInfoLoading } = useSherryNetherlandQuery();
  const { data: getSonnyFriendlyInfo, isLoading: isSonnyFriendlyInfoLoading } = useSonnyFriendlyQuery();

  const queries = [
    { ...getAnimalsInfo, isLoading: isAnimalsInfoLoading },
    { ...getBeautifulDayMonsterInfo, isLoading: isBeautifulDayMonsterInfoLoading },
    { ...getCookieMonsterInfo, isLoading: isCookieMonsterInfoLoading },
    { ...getDonaldGrumpInfo, isLoading: isDonaldGrumpInfoLoading },
    { ...getKingstonLivingstonInfo, isLoading: isKingstonLivingstonInfoLoading },
    { ...getMaggieCadabbyInfo, isLoading: isMaggieCadabbyInfoLoading },
    { ...getSherryNetherlandInfo, isLoading: isSherryNetherlandInfoLoading },
    { ...getSonnyFriendlyInfo, isLoading: isSonnyFriendlyInfoLoading }
  ];

  return { data: queries };
};
