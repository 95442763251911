import { DownOutlined, RightOutlined } from '@ant-design/icons';
import { Spin, Table } from 'antd';
import { BlockLoader } from 'components/atoms/BlockLoader';
import React, { LegacyRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { animated, useSpring } from 'react-spring';
import { useMeasure } from 'react-use';
import { useGetAvailableStockingDimensionsQuery } from 'redux/services/sonnyFriendly/sonnyFriendly';
import { useAppSelector } from 'redux/store';

const OCDTable: React.FC<{ availableStockingDimensions: { conditionId: string; dispositionId: string; ownerId: string }[] }> = ({ availableStockingDimensions }) => {
  const [ref, { height }] = useMeasure();

  const expandAnimation = useSpring({
    config: { friction: 12, bounce: 0 },
    height: height || 0
  });

  const columns: { title: string; dataIndex: string; key: string }[] = [
    {
      title: 'Owner',
      dataIndex: 'ownerId',
      key: 'ownerId'
    },
    {
      title: 'Condition',
      dataIndex: 'conditionId',
      key: 'conditionId'
    },
    {
      title: 'Disposition',
      dataIndex: 'dispositionId',
      key: 'dispositionId'
    }
  ];

  return (
    <animated.div style={{ ...{ minHeight: 10, overflow: 'hidden' }, ...expandAnimation }}>
      <div ref={ref as LegacyRef<HTMLDivElement>}>
        <Table bordered pagination={false} style={{ padding: 16, width: 1000 }} size="small" columns={columns} dataSource={availableStockingDimensions} />
      </div>
    </animated.div>
  );
};

export const AvailableStockingDimensionsTable: React.FC = () => {
  const { availableStockingDimensionsParams } = useAppSelector((state) => state);
  const [currentPage, setCurrentPage] = useState(1);
  const { data, isLoading, isFetching } = useGetAvailableStockingDimensionsQuery({
    params: { ...availableStockingDimensionsParams, offset: currentPage == 1 ? 0 : currentPage * 25 - 25, orderByDirection: 'descending', orderByField: 'createdDateTime' }
  });

  const columns: { title: string; dataIndex: string; key: string; ellipsis: boolean; width?: number; render?: (record: string) => JSX.Element }[] = [
    {
      title: 'Available Stocking Dimension ID',
      dataIndex: 'name',
      key: 'name',
      ellipsis: true,
      render: (record): JSX.Element => {
        const stockingId = data?.data.find((item) => item.name === record)?.id;

        return <Link to={`available-stocking-dimension/${stockingId}`}>{record}</Link>;
      }
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      ellipsis: true
    }
  ];

  if (isLoading) return <BlockLoader direction="loader loader--slideUp" />;

  return (
    <Spin spinning={isFetching} indicator={<BlockLoader direction="loader loader--slideUp" />}>
      <Table
        style={{ marginTop: 5 }}
        dataSource={data?.data}
        columns={columns}
        rowClassName="editable-row"
        pagination={{ total: data?.totalCount, size: 'small', current: currentPage, onChange: (page) => setCurrentPage(page), hideOnSinglePage: true, defaultPageSize: 25 }}
        rowKey={(row): string => `${row.name}`}
        expandable={{
          columnWidth: 30,
          expandIcon: ({ onExpand, expanded, record }): JSX.Element | undefined => {
            return expanded ? (
              <DownOutlined
                className="product-table__arrow"
                style={{
                  fontSize: 13,
                  padding: 5,
                  position: 'absolute',
                  transform: 'translateY(-50%)',
                  zIndex: 9999,
                  width: 24,
                  height: 24
                }}
                onClick={(e): void => {
                  e.preventDefault();
                  e.stopPropagation();
                  onExpand(record, e);
                }}
              />
            ) : (
              <RightOutlined
                className="product-table__arrow"
                style={{
                  fontSize: 13,
                  padding: 5,
                  position: 'absolute',
                  transform: 'translateY(-50%)',
                  zIndex: 9999,
                  width: 24,
                  height: 24
                }}
                onClick={(e): void => {
                  e.preventDefault();
                  e.stopPropagation();
                  onExpand(record, e);
                }}
              />
            );
          },
          expandedRowRender: (record): React.ReactNode => {
            return <OCDTable availableStockingDimensions={record.dimensions} />;
          }
        }}
      />
    </Spin>
  );
};
