import { DeleteOutlined, EditOutlined, HolderOutlined, WarningFilled } from '@ant-design/icons';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { Button, Card, Col, Modal, Row, Space, Spin, Table, Tooltip, Typography, message } from 'antd';
import { Loader } from 'components/atoms/Loader';
import { SelectInput } from 'components/atoms/SelectInput';
import { Form, FormikProvider, FormikValues, useField, useFormik, useFormikContext } from 'formik';
import { useOCD } from 'hooks/useOCD';
import update from 'immutability-helper';
import { useCallback, useRef, useState } from 'react';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useGetDivisionQuery } from 'redux/services/abbyCadabby/abbyCadabbyApi';
import { useAppSelector } from 'redux/store';
import { manager } from 'utils/dndUtils';
import { ocdValidationSchema } from './AvailableStockingDimensionCombinationsTable';

interface DraggableBodyRowProps extends React.HTMLAttributes<HTMLTableRowElement> {
  index: number;
  moveRow: (dragIndex: number, hoverIndex: number) => void;
}

export const ReplenishmentDimensionCombinationsTable = (): JSX.Element => {
  const { values, setValues } = useFormikContext<FormikValues>();
  const [{ value }, , { setValue }] = useField('dimensions');

  const { ownerOptions, conditionOptions, dispositionOptions, areOptionsLoading } = useOCD();
  const { acuityContext } = useAppSelector((state) => state.app);
  const { data: divisionData, isLoading } = useGetDivisionQuery(acuityContext?.selectedCustomer.id ?? skipToken);

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isEditOpen, setIsEditOpen] = useState<boolean>(false);
  const [index, setIndex] = useState<number>(0);
  const [modal, contextHolder] = Modal.useModal();

  const [{ value: record }, , { setValue: setRecord }] = useField(`dimensions[${index}]`);

  const type = 'DraggableBodyRow';

  const addCombination = useFormik({
    validationSchema: ocdValidationSchema,
    enableReinitialize: true,
    initialValues: {
      ownerId: '',
      conditionId: '',
      dispositionId: '',
      priority: value.length + 1
    },
    onSubmit: (newValues) => {
      const duplicateCheck = values.dimensions.some(
        ({ ownerId, conditionId, dispositionId }: any) => `${ownerId} ${conditionId} ${dispositionId}` === `${newValues.ownerId} ${newValues.conditionId} ${newValues.dispositionId}`
      );

      if (duplicateCheck) {
        message.error(`${newValues.ownerId} - ${newValues.conditionId} - ${newValues.dispositionId} already exists.`);

        return;
      }
      setValue([...value, newValues]);
      setIsOpen(false);
      addCombination.resetForm();
    }
  });
  const editCombination = useFormik({
    enableReinitialize: true,
    initialValues: {
      ownerId: record?.ownerId ?? '',
      conditionId: record?.conditionId ?? '',
      dispositionId: record?.dispositionId ?? '',
      priority: record?.priority ?? 0
    },
    onSubmit: (newValues) => {
      setRecord({ ...record, ...newValues });
      setIsEditOpen(false);
      editCombination.resetForm();
    }
  });

  const handleDeleteRow = (record: any): void => {
    if (!values.dimensions) return;

    setValues((prev: any) => ({
      ...prev,
      dimensions: values.dimensions
        ? values.dimensions
            .filter(({ ownerId, conditionId, dispositionId }: any) => `${ownerId} ${conditionId} ${dispositionId}` !== `${record.ownerId} ${record.conditionId} ${record.dispositionId}`)
            .map((item: any, i: number) => ({ ...item, priority: i + 1 }))
        : undefined
    }));
  };

  const handleEditRow = (record: any, index: number): void => {
    setIndex(index);
    setIsEditOpen(true);
  };

  const handleClose = (): void => {
    setIsOpen(false);
    setIsEditOpen(false);
    addCombination.resetForm();
    editCombination.resetForm();
  };

  const DraggableBodyRow = ({ index, moveRow, className, style, ...restProps }: DraggableBodyRowProps): JSX.Element => {
    const ref = useRef<HTMLTableRowElement>(null);

    const [{ isOver, dropClassName }, drop] = useDrop({
      accept: type,
      collect: (monitor) => {
        const { index: dragIndex } = monitor.getItem() || {};

        if (dragIndex === index) {
          return {};
        }

        return {
          isOver: monitor.isOver(),
          dropClassName: dragIndex < index ? ' drop-over-downward' : ' drop-over-upward'
        };
      },
      drop: (item: { index: number }) => {
        moveRow(item.index, index);
      }
    });
    const [, drag] = useDrag({
      type,
      item: { index },
      collect: (monitor) => ({
        isDragging: monitor.isDragging()
      })
    });

    drop(drag(ref));

    return <tr ref={ref} className={`${className}${isOver ? dropClassName : ''}`} style={{ cursor: 'move', ...style }} {...restProps} />;
  };
  const cols = [
    {
      dataIndex: 'sort',
      width: 30,
      render: () => <HolderOutlined style={{ cursor: 'grab' }} />
    },
    {
      title: 'Priority',
      dataIndex: 'priority',
      key: 'priority'
    },
    {
      title: 'Owner',
      dataIndex: 'ownerId',
      key: 'ownerId'
    },
    {
      title: 'Condition',
      dataIndex: 'conditionId',
      key: 'conditionId'
    },
    {
      title: 'Disposition',
      dataIndex: 'dispositionId',
      key: 'dispositionId'
    },
    {
      title: 'Actions',
      width: 150,
      align: 'center' as any,
      render: (_: string, record: any, i: number) => (
        <Row justify="center" gutter={[1, 1]}>
          <Col>
            <Tooltip title="Edit Combination">
              <Button size="small" onClick={(): void => handleEditRow(record, i)} icon={<EditOutlined />} />
            </Tooltip>
          </Col>
          <Col>
            {contextHolder}
            <Tooltip title="Delete Combination">
              <Button
                size="small"
                onClick={(): void => {
                  modal.confirm({
                    title: 'IF YOU CONTINUE, THE CHANGES WILL NOT GO INTO EFFECT UNTIL YOU CLICK SAVE.',
                    icon: <WarningFilled style={{ color: 'red' }} />,
                    okButtonProps: { style: { background: 'red', border: 'none' } },
                    okText: 'Delete',
                    onOk: () => handleDeleteRow(record),
                    prefixCls: 'ant-modal'
                  });
                }}
                icon={<DeleteOutlined />}
              />
            </Tooltip>
          </Col>
        </Row>
      )
    }
  ];
  const moveRow = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      if (!value) return;
      const dragRow = value[dragIndex];

      setValue(
        update(value, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragRow]
          ]
        }).map((d: any, i: number) => ({ ...d, priority: i + 1 }))
      );
    },
    [setValue, value]
  );

  const components = {
    body: {
      row: DraggableBodyRow
    }
  };

  return (
    <Card
      title={
        <Typography.Title level={4} style={{ fontWeight: 400, padding: 0, margin: 0 }}>
          Replenishment Combinations
        </Typography.Title>
      }
      headStyle={{ background: '#b0b2bc' }}
      style={{ height: '100%' }}
      extra={<Button onClick={(): void => setIsOpen(true)}>Add</Button>}>
      {/* <FormikProvider value={warehouseFormik}> */}
      <Modal
        okButtonProps={{ disabled: !Object.values(addCombination.touched).length || !addCombination.isValid }}
        onCancel={handleClose}
        width={400}
        title="Add Combination"
        onOk={(): Promise<void> => addCombination.submitForm()}
        open={isOpen}>
        <FormikProvider value={addCombination}>
          <Spin spinning={areOptionsLoading || isLoading} indicator={<Loader />}>
            <Form>
              <Space align="start" direction="vertical" size={20} style={{ width: '100%' }}>
                <Row gutter={[0, 16]}>
                  <SelectInput fieldName="ownerId" label="Owner" options={divisionData?.assignedOwners.map((owner) => ({ value: owner, label: owner }))} />
                  <SelectInput fieldName="conditionId" label="Condition" options={conditionOptions} />
                  <SelectInput fieldName="dispositionId" label="Disposition" options={dispositionOptions} />
                </Row>
              </Space>
            </Form>
          </Spin>
        </FormikProvider>
      </Modal>
      <Modal
        okButtonProps={
          {
            // disabled: _.isEqual(editCombinationFormik.initialValues, editCombinationFormik.values)
          }
        }
        width={400}
        onCancel={handleClose}
        onOk={(): Promise<void> => editCombination.submitForm()}
        title="Edit Combination"
        open={isEditOpen}>
        <FormikProvider value={editCombination}>
          <Spin spinning={areOptionsLoading || isLoading} indicator={<Loader />}>
            <Form>
              <Space align="start" direction="vertical" size={20} style={{ width: '100%' }}>
                <Row gutter={[0, 16]}>
                  <SelectInput fieldName="ownerId" label="Owner" options={divisionData?.assignedOwners.map((owner) => ({ value: owner, label: owner }))} />
                  <SelectInput fieldName="conditionId" label="Condition" options={conditionOptions} />
                  <SelectInput fieldName="dispositionId" label="Disposition" options={dispositionOptions} />
                </Row>
              </Space>
            </Form>
          </Spin>
        </FormikProvider>
      </Modal>
      <DndProvider manager={manager} context={window} backend={HTML5Backend}>
        <Table
          columns={cols}
          pagination={false}
          dataSource={values.dimensions}
          components={components}
          rowKey={(record, i): string => `${record.ownerId} - ${record.conditionId} - ${record.dispositionId}`}
          onRow={(_, index): any => {
            const attr = {
              index,
              moveRow
            };

            return attr as React.HTMLAttributes<any>;
          }}
        />
      </DndProvider>
    </Card>
  );
};
