import { ActionCreatorWithOptionalPayload } from '@reduxjs/toolkit';
import { Space } from 'antd';
import { ChangeEvent, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useDebouncedCallback } from 'use-debounce';
import { FancyInput } from '../FancyInput';

export const ReduxServiceFilter = ({ label, filterAction }: { label: string; filterAction: ActionCreatorWithOptionalPayload<string | undefined, string> }): JSX.Element => {
  const dispatch = useDispatch();

  /* ****************** Functions ****************** */
  // Handle delay of searching data
  const handleInputChange = useDebouncedCallback((ev: ChangeEvent<HTMLInputElement>) => {
    dispatch(filterAction(ev.target.value));
  }, 500);

  useEffect(() => {
    return (): void => {
      dispatch(filterAction(undefined));
    };
  }, [dispatch]);

  /* ****************** Render ****************** */
  return (
    <Space direction="vertical" size="small" style={{ marginLeft: 5, marginRight: 5 }}>
      <FancyInput style={{ width: 200 }} onChange={handleInputChange} placeholder={label} width={220} />
    </Space>
  );
};
