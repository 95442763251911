import { SaveOutlined } from '@ant-design/icons';
import { Button, Col, Dropdown, Menu, Row, Tooltip, Typography } from 'antd';
import { FormikValues, useFormikContext } from 'formik';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

interface ControlProps {
  title: string;
  submitButtonProps: {
    disabled?: boolean;
    handleSubmit: () => void;
    tooltip?: string;
  };
}

export const CreatePageControls: React.FC<ControlProps> = ({ title, submitButtonProps }) => {
  const nav = useNavigate();
  const { divisionId } = useParams();

  const { handleSubmit, disabled, tooltip } = submitButtonProps;

  const { values, setValues } = useFormikContext<FormikValues>();

  const handleSetExit = (): void => {
    setValues({ ...values, exit: true });
    handleSubmit();
  };

  const menuJSX = (
    <Menu disabledOverflow={true}>
      <Menu.Item key="1" onClick={handleSubmit}>
        Save
      </Menu.Item>
    </Menu>
  );

  return (
    <Row justify="space-between" align="middle" style={{ marginBottom: 16 }}>
      <Col>
        <Typography.Title style={{ fontWeight: 400, padding: 0, margin: 0 }} level={3}>
          {title}
        </Typography.Title>
      </Col>
      <Col style={{ display: 'flex' }}>
        <Button style={{ marginRight: 5 }} onClick={(): void => nav('/')}>
          Back
        </Button>
        <Tooltip title={tooltip ?? undefined}>
          <Dropdown.Button disabled={disabled} style={{ marginRight: 8 }} overlay={menuJSX} onClick={handleSetExit} type="primary" icon={<SaveOutlined />}>
            Save and Exit
          </Dropdown.Button>
        </Tooltip>
      </Col>
    </Row>
  );
};
