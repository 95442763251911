import { About } from 'pages/about/About';
import { CreateAvailableStockingDimension } from 'pages/availableStockingDimensions/CreateAvailableStockingDimension';
import { EditAvailableStockingDimension } from 'pages/availableStockingDimensions/EditAvailableStockingDimension';
import { EditProfilePage } from 'pages/editProfile/EditProfile';
import { Home } from 'pages/home/Home';
import { AvailableStockingDimensionsRecycleBinPage } from 'pages/recycleBinPages/AvailableStockingDimensionsRecycleBinPage';
import { ReplenishmentDimensionsRecycleBinPage } from 'pages/recycleBinPages/ReplenishmentDimensionsRecycleBinPage';
import { StockingLevelsRecycleBinPage } from 'pages/recycleBinPages/StockingLevelsRecycleBinPage';
import { WarehousePriorityRecycleBinPage } from 'pages/recycleBinPages/WarehousePriorityRecycleBinPage';
import { ReleaseNotesPage } from 'pages/releaseNotes/ReleaseNotesPage';
import { CreateReplenishmentDimension } from 'pages/replenishmentDimensions/CreateReplenishmentDimension';
import { EditReplenishmentDimension } from 'pages/replenishmentDimensions/EditReplenishmentDimension';
import { CreateStockingLevelPage } from 'pages/stockingLevel/CreateStockingLevelPage';
import { EditStockingLevelPage } from 'pages/stockingLevel/EditStockingLevelPage';
import { Unauthorized } from 'pages/unauthorized/Unauthorized';
import { CreateWarehousePriority } from 'pages/warehousePriorities/CreateWarehousePriority';
import { EditWarehousePriority } from 'pages/warehousePriorities/EditWarehousePriority';
import { Route, Routes } from 'react-router-dom';

export const MainRoutes = (): JSX.Element => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/about" element={<About />} />
      <Route path="/unauthorized" element={<Unauthorized />} />
      <Route path="/stocking-level/:stockingLevelId" element={<EditStockingLevelPage />} />
      <Route path="/stocking-level/create" element={<CreateStockingLevelPage />} />
      <Route path="/stocking-levels/recycle-bin" element={<StockingLevelsRecycleBinPage />} />
      <Route path="/warehouse-priority/recycle-bin" element={<WarehousePriorityRecycleBinPage />} />
      <Route path="/replenishment-dimesnion/recycle-bin" element={<ReplenishmentDimensionsRecycleBinPage />} />
      <Route path="/available-stocking-dimension/:availableStockingDimensionId" element={<EditAvailableStockingDimension />} />
      <Route path="/available-stocking-dimension/create" element={<CreateAvailableStockingDimension />} />
      <Route path="/available-stocking-dimension/recycle-bin" element={<AvailableStockingDimensionsRecycleBinPage />} />
      <Route path="/replenishment-dimension/:replenishmentDimensionId" element={<EditReplenishmentDimension />} />
      <Route path="/replenishment-dimension/create" element={<CreateReplenishmentDimension />} />
      <Route path="/warehouse-priority/create" element={<CreateWarehousePriority />} />
      <Route path="/warehouse-priority/:warehousePriorityId" element={<EditWarehousePriority />} />
      <Route path="/release-notes/:version" element={<ReleaseNotesPage />} />
      <Route path="/profile" element={<EditProfilePage />} />
    </Routes>
  );
};
