import { Card, Col, message, Row, Space, Spin, Typography } from 'antd';
import { FieldInput } from 'components/atoms/FieldInput';
import { Loader } from 'components/atoms/Loader';
import { CreatePageControls } from 'components/molecules/CreatePageControls';
import { WarehouseTable } from 'components/molecules/WarehouseTable';
import { FormikProvider, useFormik } from 'formik';
import { WarehousePriorityPayload } from 'models/WarehousePriorityModel';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useCreateWarehousePriorityMutation } from 'redux/services/sonnyFriendly/sonnyFriendly';
import * as yup from 'yup';

export interface AvailableStockingDimension {
  name: string;
  description: string;
  combinations: { ownerId: string; conditionId: string; dispositionId: string }[];
}

export const warehousePrioritySchema = yup.object({
  name: yup.string().label('Name').required(),
  description: yup.string().label('Description').required(),
  warehousePriorities: yup.array().min(1).required()
});

const GeneralSection: React.FC = () => {
  return (
    <Card
      style={{ height: '100%' }}
      headStyle={{ background: 'rgb(206, 213, 242)' }}
      title={
        <Typography.Title level={4} style={{ fontWeight: 400, fontSize: 22 }}>
          General
        </Typography.Title>
      }>
      <Row gutter={[30, 10]}>
        <Col span={12}>
          <Space direction="vertical" style={{ width: '100%' }} size={15}>
            <FieldInput fieldName="name" label="Name" />
          </Space>
        </Col>
        <Col span={12}>
          <Space direction="vertical" style={{ width: '100%' }} size={15}>
            <FieldInput fieldName="description" label="Description" />
          </Space>
        </Col>
      </Row>
    </Card>
  );
};

export const CreateWarehousePriority: React.FC = () => {
  const [createWarehousePriority] = useCreateWarehousePriorityMutation();
  const navigate = useNavigate();
  const formik = useFormik<WarehousePriorityPayload>({
    validationSchema: warehousePrioritySchema,
    enableReinitialize: true,
    initialValues: {
      name: '',
      description: '',
      warehousePriorities: []
    },
    onSubmit: async (values) => {
      const { exit, ...payload } = values;

      try {
        const response = await createWarehousePriority({ payload }).unwrap();

        message.success(`${response.name} has successfully been created`);
        if (exit) {
          navigate('/');
        } else {
          navigate(`/warehouse-priority/${response.id}`);
        }
        navigate('/');
      } catch (err) {
        console.log(err);
        message.error((err as { data: { errorMessage: string } }).data.errorMessage);
      }
    }
  });

  return (
    <FormikProvider value={formik}>
      <Spin spinning={formik.isSubmitting} indicator={<Loader message="Creating warehouse priority" />}>
        <Space direction="vertical" style={{ width: '100%', paddingBottom: 100 }}>
          <CreatePageControls
            submitButtonProps={{ handleSubmit: () => formik.submitForm(), disabled: Boolean(!formik.values.description || !formik.values.name || !formik.values.warehousePriorities.length) }}
            title="Create Warehouse Priority"
          />
          <GeneralSection />
          <WarehouseTable />
        </Space>
      </Spin>
    </FormikProvider>
  );
};
