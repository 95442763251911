import { useGetWarehousesQuery } from 'redux/services/kingstonLivingston/useKingstonLivingstonService';
import { useAppSelector } from 'redux/store';

interface WarehouseHook {
  warehouseOptions: { label: string; value: string }[];
  isLoading: boolean;
}

export const useWarehouses = (): WarehouseHook => {
  const dataAreaId = useAppSelector((state) => state.app.acuityContext?.selectedCustomer.business.dataAreaId);
  const { data: warehousesResponse, isLoading } = useGetWarehousesQuery({ dataAreaId: dataAreaId ?? '', overrideSkipTake: true }, { skip: !dataAreaId });

  const warehouseOptions =
    warehousesResponse?.data
      .filter((warehouse) => warehouse.warehouseId.toLocaleLowerCase() !== 'ds'.toLocaleLowerCase())
      .map((warehouse) => ({ label: warehouse.warehouseId, value: warehouse.warehouseId })) || [];

  return { warehouseOptions, isLoading };
};
