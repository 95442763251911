import { Card, Col, message, Row, Space, Spin, Typography } from 'antd';
import { FieldInput } from 'components/atoms/FieldInput';
import { FieldInputSelect } from 'components/atoms/FieldInputSelect';
import { Loader } from 'components/atoms/Loader';
import { CreatePageControls } from 'components/molecules/CreatePageControls';
import { ReplenishmentDimensionCombinationsTable } from 'components/organisms/ReplenishmentDimensionCombinationsTable';
import { ReplenishmentDimensionWarehouseTable } from 'components/organisms/ReplenishmentDimensionWarehouseTable';
import { FormikProvider, useFormik } from 'formik';
import React from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useNavigate } from 'react-router-dom';
import { useCreateReplenishmentDimensionMutation } from 'redux/services/sonnyFriendly/sonnyFriendly';
import * as yup from 'yup';
import { ReplenishmentDimension } from './EditReplenishmentDimension';

export const replenishmentDimensionsSchema = yup.object({
  name: yup.string().label('Name').required(),
  replenishmentType: yup.string().label('Type').required(),
  description: yup.string().label('Description').required(),
  dimensions: yup.array().min(1).required(),
  warehouses: yup.array().min(1).required()
});

const GeneralSection: React.FC = () => {
  return (
    <Card
      style={{ height: '100%' }}
      headStyle={{ background: 'rgb(206, 213, 242)' }}
      title={
        <Typography.Title level={4} style={{ fontWeight: 400, fontSize: 22 }}>
          General
        </Typography.Title>
      }>
      <Row gutter={[30, 10]}>
        <Col span={12}>
          <Space direction="vertical" style={{ width: '100%' }} size={15}>
            <FieldInput fieldName="name" label="Name" />
          </Space>
        </Col>
        <Col span={12}>
          <Space direction="vertical" style={{ width: '100%' }} size={15}>
            <FieldInput fieldName="description" label="Description" />
          </Space>
        </Col>
        <Col span={12}>
          <Space direction="vertical" style={{ width: '100%' }} size={15}>
            <FieldInputSelect options={['Purchase', 'Test Order', 'Transfer']} fieldName="replenishmentType" label="Replenishment Type" />
          </Space>
        </Col>
      </Row>
    </Card>
  );
};

export const CreateReplenishmentDimension: React.FC = () => {
  const [createReplenishmentDimension] = useCreateReplenishmentDimensionMutation();
  const navigate = useNavigate();
  const formik = useFormik<ReplenishmentDimension>({
    validationSchema: replenishmentDimensionsSchema,
    enableReinitialize: true,
    initialValues: {
      name: '',
      replenishmentType: '',
      description: '',
      dimensions: [],
      warehouses: [],
      exit: false
    },
    onSubmit: async (values) => {
      const { exit, ...payload } = values;

      try {
        const response = await createReplenishmentDimension({ payload: { ...payload, isActive: true } }).unwrap();

        message.success(`${response.name} successfully created`);
        if (exit) {
          navigate('/');
        } else {
          navigate(`/replenishment-dimension/${response.id}`);
        }
      } catch (err) {
        console.log(err);
        message.error((err as { data: { errorMessage: string } }).data.errorMessage);
      }
    }
  });

  const isDisabled = Boolean(!formik.values.description || !formik.values.name || !formik.values.replenishmentType || !formik.values.dimensions.length || !formik.values.warehouses.length);

  return (
    <FormikProvider value={formik}>
      <Spin spinning={formik.isSubmitting} indicator={<Loader message="Creating replenishment dimension" />}>
        <Space direction="vertical" style={{ width: '100%', paddingBottom: 100 }}>
          <CreatePageControls submitButtonProps={{ handleSubmit: () => formik.submitForm(), disabled: isDisabled }} title="Create Replenishment Dimension" />
          <GeneralSection />
          <DndProvider backend={HTML5Backend}>
            <ReplenishmentDimensionCombinationsTable />
            <ReplenishmentDimensionWarehouseTable />
          </DndProvider>
        </Space>
      </Spin>
    </FormikProvider>
  );
};
