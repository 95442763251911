import { InputNumber } from 'antd';
import { FormLabel } from 'components/UI/FormLabel';
import { Field, FieldProps } from 'formik';
import { CSSProperties, FC } from 'react';

interface Props {
  fieldName: string;
  type?: string;
  label: string;
  labelStyle?: CSSProperties;
}

export const FieldInputNumber: FC<Props> = ({ fieldName, type, label, labelStyle }) => {
  return (
    <Field name={fieldName}>
      {({
        field, // { name, value, onChange, onBlur }
        form: { setFieldTouched, setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
        meta
      }: FieldProps): JSX.Element => (
        <div>
          <FormLabel style={labelStyle} label={label} />
          <InputNumber
            {...field}
            onChange={(val): void => setFieldValue(fieldName, val)}
            controls={false}
            min={1}
            onFocus={(): void => setFieldTouched(fieldName, false)}
            style={{ width: '100%', borderColor: meta.error && meta.touched ? '#dc3545' : undefined }}
            type={type}
          />
          <div className={meta.touched && meta.error ? 'field-error-show' : 'field-error-hide'}>{meta.error}</div>
        </div>
      )}
    </Field>
  );
};
