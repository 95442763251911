import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SonnyFriendlyParams } from 'models/InventoryStockingLevelsModel';

const initialState: SonnyFriendlyParams = {
  offset: 0,
  nameContains: '',
  descriptionContains: '',
  returnDeletedDataOnly: undefined,
  orderByDirection: undefined,
  orderByField: undefined
};

export const availableStockingDimensionsParams = createSlice({
  name: 'availableStockingDimensionsParams',
  initialState,
  reducers: {
    setAvailableStockingDimensionOffset: (state, { payload }: PayloadAction<SonnyFriendlyParams['offset']>) => {
      state.offset = payload;
    },
    setAvailableStockingDimensionNameContains: (state, { payload }: PayloadAction<SonnyFriendlyParams['nameContains']>) => {
      state.nameContains = payload;
    },
    setAvailableStockingDimensionDescriptionContains: (state, { payload }: PayloadAction<SonnyFriendlyParams['descriptionContains']>) => {
      state.descriptionContains = payload;
    },
    setAvailableStockingDimensionOrderByDirection: (state, { payload }: PayloadAction<SonnyFriendlyParams['orderByDirection']>) => {
      state.orderByDirection = payload;
    },
    setAvailableStockingDimensionOrderByField: (state, { payload }: PayloadAction<SonnyFriendlyParams['orderByField']>) => {
      state.orderByField = payload;
    },
    setAvailableStockingDimensionReturnDeletedDataOnly: (state, { payload }: PayloadAction<SonnyFriendlyParams['returnDeletedDataOnly']>) => {
      state.returnDeletedDataOnly = payload;
    }
  }
});

export const {
  setAvailableStockingDimensionDescriptionContains,
  setAvailableStockingDimensionNameContains,
  setAvailableStockingDimensionOffset,
  setAvailableStockingDimensionReturnDeletedDataOnly,
  setAvailableStockingDimensionOrderByDirection,
  setAvailableStockingDimensionOrderByField
} = availableStockingDimensionsParams.actions;
