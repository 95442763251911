import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type FilterState = {
  productNumberContains?: string;
  warehouseContains?: string;
  manufacturerContains?: string;
  categoryContains?: string;
  isActive?: boolean;
};

const initialState: FilterState = {
  productNumberContains: '',
  warehouseContains: '',
  manufacturerContains: '',
  categoryContains: '',
  isActive: true
};

export const stockingLevelFilters = createSlice({
  name: 'stockingLevelFilters',
  initialState,
  reducers: {
    setProductNumberContains: (state, { payload }: PayloadAction<FilterState['productNumberContains']>) => {
      state.productNumberContains = payload;
    },
    setManufacturerContains: (state, { payload }: PayloadAction<FilterState['manufacturerContains']>) => {
      state.manufacturerContains = payload;
    },
    setCategoryContains: (state, { payload }: PayloadAction<FilterState['categoryContains']>) => {
      state.categoryContains = payload;
    },
    setWarehouseContains: (state, { payload }: PayloadAction<FilterState['warehouseContains']>) => {
      state.warehouseContains = payload;
    },
    setIsActive: (state, { payload }: PayloadAction<FilterState['isActive']>) => {
      state.isActive = payload;
    }
  }
});

export const { setCategoryContains, setManufacturerContains, setProductNumberContains, setWarehouseContains, setIsActive } = stockingLevelFilters.actions;
