import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SonnyFriendlyParams } from 'models/InventoryStockingLevelsModel';

const initialState: SonnyFriendlyParams = {
  offset: 0,
  nameContains: '',
  descriptionContains: '',
  returnDeletedDataOnly: undefined,
  orderByDirection: undefined,
  orderByField: undefined
};

export const replenishmentDimensionParams = createSlice({
  name: 'replenishmentDimensionParams',
  initialState,
  reducers: {
    setReplenishmentDimensionOffset: (state, { payload }: PayloadAction<SonnyFriendlyParams['offset']>) => {
      state.offset = payload;
    },
    setReplenishmentDimensionNameContains: (state, { payload }: PayloadAction<SonnyFriendlyParams['nameContains']>) => {
      state.nameContains = payload;
    },
    setReplenishmentDimensionDescriptionContains: (state, { payload }: PayloadAction<SonnyFriendlyParams['descriptionContains']>) => {
      state.descriptionContains = payload;
    },
    setReplenishmentDimensionReturnDeletedDataOnly: (state, { payload }: PayloadAction<SonnyFriendlyParams['returnDeletedDataOnly']>) => {
      state.returnDeletedDataOnly = payload;
    },
    setReplenishmentDimensionOrderByDirection: (state, { payload }: PayloadAction<SonnyFriendlyParams['orderByDirection']>) => {
      state.orderByDirection = payload;
    },
    setReplenishmentDimensionOrderByField: (state, { payload }: PayloadAction<SonnyFriendlyParams['orderByField']>) => {
      state.orderByField = payload;
    }
  }
});

export const {
  setReplenishmentDimensionDescriptionContains,
  setReplenishmentDimensionNameContains,
  setReplenishmentDimensionOffset,
  setReplenishmentDimensionReturnDeletedDataOnly,
  setReplenishmentDimensionOrderByDirection,
  setReplenishmentDimensionOrderByField
} = replenishmentDimensionParams.actions;
