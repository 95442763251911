import { Select } from 'antd';
import { FormLabel } from 'components/UI/FormLabel';
import { Field, FieldProps } from 'formik';
import { WarehousePriorities } from 'models/WarehousePriorityModel';
import { CSSProperties, FC, useMemo } from 'react';

interface Props {
  fieldName: string;
  type?: string;
  label: string;
  labelStyle?: CSSProperties;
  availableWarehouses: WarehousePriorities[];
  isLoading: boolean;
}

export const AvailableWarehouseSelectField: FC<Props> = ({ fieldName, label, labelStyle, availableWarehouses, isLoading }) => {
  /* ****************** JSX / Variables ****************** */

  const warehousesOptions = useMemo(() => availableWarehouses.map((warehouse) => ({ label: warehouse.warehouseId, value: warehouse.warehouseId })), [availableWarehouses]);

  return (
    <Field name={fieldName}>
      {({
        field, // { name, value, onChange, onBlur }
        form: { setFieldTouched, setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
        meta
      }: FieldProps): JSX.Element => (
        <div>
          <FormLabel style={labelStyle} label={label} />
          <Select
            {...field}
            loading={isLoading}
            options={warehousesOptions}
            onFocus={(): void => setFieldTouched(fieldName, false)}
            style={{ borderColor: meta.error && meta.touched ? '#dc3545' : undefined, width: 120 }}
            onChange={(val): void => setFieldValue(fieldName, val)}
          />
          <div className={meta.touched && meta.error ? 'field-error-show' : 'field-error-hide'}>{meta.error}</div>
        </div>
      )}
    </Field>
  );
};
