import { DeleteOutlined } from '@ant-design/icons';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { Button, Card, Col, Divider, List, Row, Spin } from 'antd';
import { BlockLoader } from 'components/atoms/BlockLoader';
import EllipsesText from 'components/atoms/EllipsiesText';
import { InventoryStockingDescriptionContainsFilter } from 'components/atoms/filters/InventoryStockingDescriptionContainsFilter';
import { InventoryStockingNameContainsFilter } from 'components/atoms/filters/InventoryStockingNameContainsFilter';
import { Loader } from 'components/atoms/Loader';
import { WimsicalError } from 'components/atoms/WimsicalError';
import { useGetPermissions } from 'hooks/useAuthentication';
import { InlineStylesModel } from 'models/InlineStylesModel';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setInventoryStockingOffset } from 'redux/services/sonnyFriendly/inventoryStockingParams';
import { useGetInventoryStockingLevelsQuery } from 'redux/services/sonnyFriendly/sonnyFriendly';
import { useAppSelector } from 'redux/store';

const styles: InlineStylesModel = {
  card: {
    backgroundColor: 'white',
    borderRadius: 5,
    margin: '6px'
  },
  categoryId: {
    color: 'grey',
    lineHeight: '.7',
    paddingBottom: '13px'
  },
  manufacturer: {
    fontWeight: 600
  },
  dispositionRuleType: {
    color: 'grey',
    height: '15px'
  },
  partDescription: {
    color: 'grey'
  },
  itemName: {
    fontWeight: 600,
    fontSize: 20,
    color: '#203175'
  },
  salesOrderType: {
    color: 'grey',
    textAlign: 'right'
  },
  robotPadding: {
    paddingTop: 5,
    cursor: 'pointer'
  },
  smallGrouping: {
    color: 'grey',
    fontSize: '8',
    background: '#8383831a',
    padding: 4,
    borderRadius: 100
  },
  view: {
    color: '#203175',
    fontWeight: 600,
    fontSize: '15px',
    cursor: 'pointer'
  },
  test: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis'
  }
};

export const InventoryStockingGrid = (): JSX.Element => {
  const { inventoryStockingParams } = useAppSelector((state) => state);
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const handleRecycleBinNavigate = (): void => {
    navigate(`/stocking-levels/recycle-bin`);
  };
  const { data, isLoading, isFetching, isError, error } = useGetInventoryStockingLevelsQuery({ ...inventoryStockingParams, orderByDirection: 'descending', orderByField: 'createdDateTime' });
  const { offset } = useAppSelector((state) => state.inventoryStockingParams);
  const { userRole } = useGetPermissions();

  if (isError) {
    const queryError = error as FetchBaseQueryError;

    return (
      <Row justify="center" style={{ width: '100%' }}>
        <Col span={24}>
          <WimsicalError status={typeof queryError.status === 'number' ? queryError.status : 500} message={''} />
        </Col>
      </Row>
    );
  }
  const handleNext = (): void => {
    dispatch(setInventoryStockingOffset(offset + 25));
  };
  const hasMore = Boolean(!!data && data.data.length < data.totalCount);

  return (
    <Row>
      <Col span={24}>
        <Row gutter={8} justify="space-between" style={{ marginTop: 8, background: 'white', padding: 20 }}>
          <Row gutter={8}>
            <Col>
              <InventoryStockingNameContainsFilter />
            </Col>
            <Col>
              <InventoryStockingDescriptionContainsFilter />
            </Col>
          </Row>
          <Col>
            <Button hidden={userRole === 'user'} onClick={(): void => navigate(`/stocking-level/create`)}>
              Add Level
            </Button>
            <Button hidden={userRole === 'user'} type="primary" style={{ marginLeft: 5 }} icon={<DeleteOutlined />} onClick={handleRecycleBinNavigate}>
              Recycle-Bin
            </Button>
          </Col>
        </Row>
      </Col>
      <Divider style={{ margin: '8px 0' }} />
      <Col span={24}>
        <InfiniteScroll
          dataLength={data?.data.length || 0}
          next={handleNext}
          hasMore={hasMore}
          initialScrollY={0}
          loader={
            <Row justify="center">
              <Spin indicator={<BlockLoader direction="loader loader--slideUp" />} spinning={isLoading || isFetching} />
            </Row>
          }>
          <List
            loading={{ spinning: isLoading || isFetching, indicator: <Loader /> }}
            dataSource={data?.data}
            grid={{ gutter: 4, column: 4, xs: 1, sm: 1, md: 2, lg: 2, xl: 3, xxl: 4 }}
            style={{ width: '100%' }}
            renderItem={(item): React.ReactNode => (
              <Col onClick={(): void => navigate(`/stocking-level/${item.id}`)}>
                <Card hoverable style={styles.card}>
                  <Row gutter={[2, 0]} justify="space-between" align="middle">
                    <Col style={styles.itemName} span={20}>
                      <EllipsesText tooltipCharacterCount={23} text={item.name} />
                    </Col>
                  </Row>
                  <Row justify="space-between" align="top"></Row>
                  <Row style={styles.dispositionRuleType}>{item.description}</Row>
                  <Row justify="center">
                    <Col span={24} style={{ marginBottom: 10 }}>
                      <Divider />
                    </Col>
                  </Row>
                  <Row justify="space-between" align="bottom">
                    <Col>
                      <Row style={styles.smallGrouping} gutter={16} align="middle">
                        <Col>{item.stockingLevelType}</Col>
                      </Row>
                    </Col>
                  </Row>
                </Card>
              </Col>
            )}
          />
        </InfiniteScroll>
      </Col>
    </Row>
  );
};
