import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SonnyFriendlyParams } from 'models/InventoryStockingLevelsModel';

const initialState: SonnyFriendlyParams = {
  offset: 0,
  nameContains: '',
  descriptionContains: '',
  returnDeletedDataOnly: undefined,
  orderByDirection: undefined,
  orderByField: undefined
};

export const warehousePriorityParams = createSlice({
  name: 'warehousePriorityParams',
  initialState,
  reducers: {
    setWarehousePriorityOffset: (state, { payload }: PayloadAction<SonnyFriendlyParams['offset']>) => {
      state.offset = payload;
    },
    setWarehousePriorityNameContains: (state, { payload }: PayloadAction<SonnyFriendlyParams['nameContains']>) => {
      state.nameContains = payload;
    },
    setWarehousePriorityDescriptionContains: (state, { payload }: PayloadAction<SonnyFriendlyParams['descriptionContains']>) => {
      state.descriptionContains = payload;
    },
    setWarehousePriorityReturnDeletedDataOnly: (state, { payload }: PayloadAction<SonnyFriendlyParams['returnDeletedDataOnly']>) => {
      state.returnDeletedDataOnly = payload;
    },
    setWarehousePriorityOrderByDirection: (state, { payload }: PayloadAction<SonnyFriendlyParams['orderByDirection']>) => {
      state.orderByDirection = payload;
    },
    setWarehousePriorityorderByField: (state, { payload }: PayloadAction<SonnyFriendlyParams['orderByField']>) => {
      state.orderByField = payload;
    }
  }
});

export const {
  setWarehousePriorityDescriptionContains,
  setWarehousePriorityNameContains,
  setWarehousePriorityOffset,
  setWarehousePriorityReturnDeletedDataOnly,
  setWarehousePriorityOrderByDirection,
  setWarehousePriorityorderByField
} = warehousePriorityParams.actions;
