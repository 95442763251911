import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SonnyFriendlyParams } from 'models/InventoryStockingLevelsModel';

const initialState: SonnyFriendlyParams = {
  offset: 0,
  nameContains: '',
  descriptionContains: '',
  returnDeletedDataOnly: undefined,
  orderByDirection: undefined,
  orderByField: undefined
};

export const inventoryStockingParams = createSlice({
  name: 'inventoryStockingParams',
  initialState,
  reducers: {
    setInventoryStockingOffset: (state, { payload }: PayloadAction<SonnyFriendlyParams['offset']>) => {
      state.offset = payload;
    },
    setInventoryStockingNameContains: (state, { payload }: PayloadAction<SonnyFriendlyParams['nameContains']>) => {
      state.nameContains = payload;
    },
    setInventoryStockingDescriptionContains: (state, { payload }: PayloadAction<SonnyFriendlyParams['descriptionContains']>) => {
      state.descriptionContains = payload;
    },
    setInventoryStockingReturnDeletedDataOnly: (state, { payload }: PayloadAction<SonnyFriendlyParams['returnDeletedDataOnly']>) => {
      state.returnDeletedDataOnly = payload;
    },
    setInventoryStockingOrderByDIrection: (state, { payload }: PayloadAction<SonnyFriendlyParams['orderByDirection']>) => {
      state.orderByDirection = payload;
    },
    setInventoryStockingOrderByField: (state, { payload }: PayloadAction<SonnyFriendlyParams['orderByField']>) => {
      state.orderByField = payload;
    }
  }
});

export const {
  setInventoryStockingDescriptionContains,
  setInventoryStockingNameContains,
  setInventoryStockingOffset,
  setInventoryStockingReturnDeletedDataOnly,
  setInventoryStockingOrderByDIrection,
  setInventoryStockingOrderByField
} = inventoryStockingParams.actions;
