import { FetchBaseQueryError, skipToken } from '@reduxjs/toolkit/dist/query';
import { Card, Col, message, Row, Space, Spin, Typography } from 'antd';
import { FieldInput } from 'components/atoms/FieldInput';
import { Loader } from 'components/atoms/Loader';
import { WimsicalError } from 'components/atoms/WimsicalError';
import { EditPageControls } from 'components/molecules/EditPageControls';
import { WarehouseTable } from 'components/molecules/WarehouseTable';
import { FormikProvider, useFormik } from 'formik';
import _ from 'lodash';
import { WarehousePriorityPayload } from 'models/WarehousePriorityModel';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDeleteWarehousePriorityMutation, useGetWarehousePriorityQuery, useUpdateWarehousePriorityMutation } from 'redux/services/sonnyFriendly/sonnyFriendly';
import { warehousePrioritySchema } from './CreateWarehousePriority';

export interface ReplenishmentDimension {
  name: string;
  type: string;
  description: string;
  combinations: { ownerId: string; conditionId: string; dispositionId: string; priority: number }[];
  warehouses: { warehouseId: string; priority: number }[];
}

const GeneralSection: React.FC = () => {
  return (
    <Card
      style={{ height: '100%' }}
      headStyle={{ background: 'rgb(206, 213, 242)' }}
      title={
        <Typography.Title level={4} style={{ fontWeight: 400, fontSize: 22 }}>
          General
        </Typography.Title>
      }>
      <Row gutter={[30, 10]}>
        <Col span={12}>
          <Space direction="vertical" style={{ width: '100%' }} size={15}>
            <FieldInput fieldName="name" label="Name" />
          </Space>
        </Col>
        <Col span={12}>
          <Space direction="vertical" style={{ width: '100%' }} size={15}>
            <FieldInput fieldName="description" label="Description" />
          </Space>
        </Col>
      </Row>
    </Card>
  );
};

export const EditWarehousePriority: React.FC = () => {
  const { warehousePriorityId } = useParams();
  const nav = useNavigate();

  const { data, isLoading, isFetching, isError, error } = useGetWarehousePriorityQuery(warehousePriorityId ?? skipToken);
  const [updateWarehousePriority, { isLoading: isUpdating }] = useUpdateWarehousePriorityMutation();
  const [deleteWarehousePriority, { isLoading: isDeleting }] = useDeleteWarehousePriorityMutation();
  const navigate = useNavigate();

  const formik = useFormik<WarehousePriorityPayload>({
    validationSchema: warehousePrioritySchema,
    enableReinitialize: true,
    initialValues: {
      name: data?.name ?? '',
      description: data?.description ?? '',
      warehousePriorities: data?.warehousePriorities ?? [],
      exit: false
    },
    onSubmit: async (values) => {
      const { exit, ...payload } = values;

      if (!warehousePriorityId) return message.error('No id found, please the refresht the page');

      try {
        const response = await updateWarehousePriority({ warehousePriorityId, payload: { ...data, ...payload } }).unwrap();

        message.success(`${response.name} has been successfully updated`);

        if (exit) {
          nav('/');
        }
      } catch (err) {
        console.log(err, 'error');
        message.error((err as { data: { errorMessage: string } })?.data?.errorMessage || 'An error occurred while submitting the form. Please try again.');
        formik.setValues({ ...formik.values, exit: false });
      }
      // console.log(values);
    }
  });

  const handleDelete = async (): Promise<void> => {
    if (!warehousePriorityId) return message.error('An error occured (no id) the team has been notified');
    try {
      await deleteWarehousePriority(warehousePriorityId);

      message.success(`Warehouse priority was succesfully deleted`);
      navigate('/');
    } catch (err) {
      console.log(err);
      message.error((err as { data: { errorMessage: string } }).data.errorMessage);
    }
  };

  const isValid = formik.isValid && !_.isEqual(formik.values, formik.initialValues);

  if (isLoading || isFetching) return <Loader />;
  if (isError) {
    const queryError = error as FetchBaseQueryError;

    return (
      <Row justify="center" style={{ width: '100%' }}>
        <Col span={24}>
          <WimsicalError status={typeof queryError.status === 'number' ? queryError.status : 500} message={''} />
        </Col>
      </Row>
    );
  }

  return (
    <FormikProvider value={formik}>
      <Spin spinning={formik.isSubmitting || isDeleting} indicator={<Loader message={formik.isSubmitting ? 'Updating warehouse priority' : isDeleting ? 'Deleting warehouse priority' : ''} />}>
        <Space direction="vertical" style={{ width: '100%', paddingBottom: 100 }}>
          <EditPageControls
            description="warehouse priority"
            title="Edit Warehouse Priority"
            submitButtonProps={{ handleSubmit: () => formik.submitForm(), disabled: !isValid }}
            deleteButtonProps={{ handleDelete: () => handleDelete() }}
          />
          <GeneralSection />
          <WarehouseTable />
        </Space>
      </Spin>
    </FormikProvider>
  );
};
