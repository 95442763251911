import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { AnimalsCondition, AnimalsConditionsParams } from 'models/AnimalsCondition';
import { AnimalsDisposition, AnimalsDispositionsParams } from 'models/AnimalsDisposition';
import { AnimalsOwner, AnimalsOwnersParams } from 'models/AnimalsOwner';
import { ApiMultipleResponse } from 'models/ApiModels';
import { ReduxState } from 'redux/store';

const baseUrl = process.env.REACT_APP_ANIMALS_BASE_URL;
const functionsKey = process.env.REACT_APP_ANIMALS_HOST_KEY;

const baseQuery = fetchBaseQuery({
  baseUrl,
  prepareHeaders: (headers, { getState }) => {
    const token = (getState() as ReduxState).app.accessToken;

    if (token && functionsKey) {
      headers.set('authorization', `Bearer ${token}`);
      headers.set('x-functions-key', functionsKey);
      headers.set('Content-Type', 'application/json');
    }

    return headers;
  }
});

export const animalsApi = createApi({
  reducerPath: 'animalsApi',
  baseQuery,
  endpoints: (builder) => ({
    getOwners: builder.query<ApiMultipleResponse<AnimalsOwner>, AnimalsOwnersParams>({
      query: (params) => ({
        url: '/owners',
        params
      })
    }),
    getConditions: builder.query<ApiMultipleResponse<AnimalsCondition>, AnimalsConditionsParams>({
      query: (params) => ({
        url: '/conditions',
        params
      })
    }),
    getDispositions: builder.query<ApiMultipleResponse<AnimalsDisposition>, AnimalsDispositionsParams>({
      query: (params) => ({
        url: '/dispositions',
        params
      })
    }),
    getInfo: builder.query<{ version: string; apiName: string }, void>({
      async queryFn(args, queryApi, extraOptions, fetchWithBQ): Promise<any> {
        const version = await fetchWithBQ({ url: '/diagnostics/version', responseHandler: (response): Promise<string> => response.text() });
        const apiName = await fetchWithBQ({ url: '/diagnostics/apiName', responseHandler: (response): Promise<string> => response.text() });

        return { data: { version: version.data, apiName: apiName.data } };
      }
    }),
    getVersion: builder.query<string, void>({
      query: () => ({
        url: '/diagnostics/version',
        responseHandler: (response): Promise<string> => response.text()
      })
    }),
    getApiName: builder.query<string, void>({
      query: () => ({
        url: '/diagnostics/apiName',
        responseHandler: (response): Promise<string> => response.text()
      })
    })
  })
});

export const { useGetOwnersQuery, useGetConditionsQuery, useGetDispositionsQuery, useGetVersionQuery, useGetApiNameQuery, useGetInfoQuery } = animalsApi;
