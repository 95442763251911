import { Typography } from 'antd';
import { TitleProps } from 'antd/lib/typography/Title';
import React from 'react';

interface Props extends TitleProps {
  label: string;
}

export const FormLabel: React.FC<Props> = ({ label, ...rest }) => {
  return (
    <Typography.Text {...rest} style={{ width: '100%', display: 'block' }}>
      {label}
    </Typography.Text>
  );
};
