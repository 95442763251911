import { Button, Col, List, message, Row, Space, Typography } from 'antd';
import { ReduxServiceFilter } from 'components/atoms/filters/ReduxServiceFilter';
import { Loader } from 'components/atoms/Loader';
import { RecycleCardReplenishment } from 'components/atoms/RecycleCardReplenishment';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setReplenishmentDimensionDescriptionContains, setReplenishmentDimensionNameContains } from 'redux/services/sonnyFriendly/replenishmentDimensionParams';
import { useGetReplenishmentDimensionsQuery, useRecoverReplenishmentDimensionMutation } from 'redux/services/sonnyFriendly/sonnyFriendly';
import { setSelectedReplenishmentDimensions } from 'redux/slices/selectedSotckingLevelsSlice';
import { useAppSelector } from 'redux/store';

export const ReplenishmentDimensionsRecycleBinPage = (): JSX.Element => {
  const { selectedReplenishmentDimensions } = useAppSelector((state) => state.selectedStockingLevels);
  const [recoverDimension, { isLoading: isRecovering }] = useRecoverReplenishmentDimensionMutation();
  const dispatch = useDispatch();
  const { replenishmentDimensionParams } = useAppSelector((state) => state);

  const navigate = useNavigate();
  const { data, isLoading, isFetching, isError, error } = useGetReplenishmentDimensionsQuery({ params: { ...replenishmentDimensionParams, returnDeletedDataOnly: true } });

  const handleRestore = (): void => {
    try {
      selectedReplenishmentDimensions.forEach(async (level) => {
        await recoverDimension(level.id);
      });
      message.success('Replenishment dimensions seccussfully recovered');
    } catch (err) {
      console.log(err);
      message.error((err as { data: { errorMessage: string } }).data.errorMessage);
    }
  };

  useEffect(() => {
    return (): void => {
      dispatch(setSelectedReplenishmentDimensions([]));
    };
  }, [dispatch]);

  return (
    <Row gutter={[5, 5]}>
      <Col span={24}>
        <Row justify="space-between">
          <Col>
            <Typography.Title style={{ fontWeight: 300, margin: 0, padding: 0 }} level={5}>
              Search Replenishment Dimseions - Recycle Bin
            </Typography.Title>
          </Col>
          <Col>
            <Button onClick={(): void => navigate('/')}>Back to Home</Button>
          </Col>
        </Row>
      </Col>
      <Col span={24}>
        <Row style={{ padding: 12, background: 'white' }}>
          <Space>
            <ReduxServiceFilter label="Replenishment Dimension ID" filterAction={setReplenishmentDimensionNameContains} />
            <ReduxServiceFilter label="Description" filterAction={setReplenishmentDimensionDescriptionContains} />
          </Space>
        </Row>
      </Col>
      <Col style={{ marginTop: 5 }} span={24}>
        <Row justify="space-between">
          <Col>
            <Space style={{ width: '100%' }}>
              <Button onClick={() => dispatch(setSelectedReplenishmentDimensions(data?.data ?? []))}>Select All</Button>
              <Button onClick={() => dispatch(setSelectedReplenishmentDimensions([]))} type="primary">
                Clear All
              </Button>
            </Space>
          </Col>
          <Col>
            <Button loading={isRecovering} onClick={handleRestore} disabled={!selectedReplenishmentDimensions.length}>
              Restore Selected
            </Button>
          </Col>
        </Row>
      </Col>
      <Col span={24}>
        <Row>
          <List
            loading={{ spinning: isLoading || isFetching, indicator: <Loader /> }}
            dataSource={data?.data}
            grid={{ gutter: 4, column: 4, xs: 1, sm: 1, md: 2, lg: 2, xl: 3, xxl: 4 }}
            style={{ width: '100%', margin: 0, padding: 0 }}
            renderItem={(item): React.ReactNode => <RecycleCardReplenishment item={item} />}
          />
        </Row>
      </Col>
    </Row>
  );
};
