import { useGetConditionsQuery, useGetDispositionsQuery, useGetOwnersQuery } from 'redux/services/animals/animalsApi';

interface OCDHook {
  ownerOptions:
    | {
        label: string;
        value: string;
      }[]
    | undefined;
  conditionOptions:
    | {
        label: string;
        value: string;
      }[]
    | undefined;
  dispositionOptions:
    | {
        label: string;
        value: string;
      }[]
    | undefined;
  areOptionsLoading: boolean;
}

export const useOCD = (): OCDHook => {
  const { data: owners, isLoading: ownersLoading } = useGetOwnersQuery({ overrideSkipTake: true, offset: 0 });
  const { data: conditions, isLoading: conditionsLoading } = useGetConditionsQuery({ overrideSkipTake: true, offset: 0 });
  const { data: dispositions, isLoading: dispositionLoading } = useGetDispositionsQuery({ overrideSkipTake: true, offset: 0 });

  const areOptionsLoading = Boolean(ownersLoading || conditionsLoading || dispositionLoading);

  const ownerOptions = owners?.data.map((owner) => ({ label: owner.name, value: owner.name }));
  const conditionOptions = conditions?.data.map((condition) => ({ label: condition.name, value: condition.name }));
  const dispositionOptions = dispositions?.data.map((disposition) => ({ label: disposition.name, value: disposition.name }));

  return { ownerOptions, conditionOptions, dispositionOptions, areOptionsLoading };
};
