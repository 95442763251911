import { PlusCircleOutlined } from '@ant-design/icons';
import { Button, Col, Modal, Row, Typography } from 'antd';
import { ProductSearchTab } from 'components/molecules/ProductSearchTab';
import { useFormikContext } from 'formik';
import { InventoryStockingLevelsPayload } from 'models/InventoryStockingLevelsModel';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { setFilterCategory, setFilterManufacturer, setFilterProductName, setFilterProductNumber, setOffset } from 'redux/slices/productsInfoSlice';
import { ProductsSearchFilters } from './ProductsSearchFilters';

export const ProductsSearchModal: React.FC = () => {
  const { values } = useFormikContext<InventoryStockingLevelsPayload>();

  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const isView = pathname.includes('/view');

  const isAddProductDisabled = Boolean(!values.warehousePriorityId || !values.availableStockDimensionId || !values.replenishmentDimensionId || !values.reasonCodes.length);

  const handleModalClose = (): void => {
    setIsOpen(false);
    dispatch(setOffset(0));
    dispatch(setFilterProductName(''));
    dispatch(setFilterProductNumber(''));
    dispatch(setFilterManufacturer(''));
    dispatch(setFilterCategory(''));
  };

  const titleJsx = (
    <Row align="middle" style={{ width: '100%', padding: '0 20px' }} justify="space-between">
      <Row gutter={[16, 16]} align="middle">
        <Col>
          <Typography.Title style={{ margin: 0, padding: 0, fontWeight: 150 }} level={4}>
            Add Line Items
          </Typography.Title>
        </Col>
      </Row>
      <Row>
        <Col>
          <ProductsSearchFilters />
        </Col>
      </Row>
    </Row>
  );

  return (
    <>
      <Row gutter={[8, 8]}>
        <Col>
          <Button type="primary" disabled={isAddProductDisabled} onClick={(): void => setIsOpen(true)} icon={<PlusCircleOutlined style={{ fontSize: 15 }} />}>
            Add Product
          </Button>
        </Col>
      </Row>

      <Modal
        keyboard
        onCancel={handleModalClose}
        closable
        title={titleJsx}
        onOk={handleModalClose}
        okButtonProps={{ style: { display: 'none' } }}
        cancelButtonProps={{ style: { display: 'none' } }}
        width={1100}
        open={isOpen}>
        <ProductSearchTab />
      </Modal>
    </>
  );
};
