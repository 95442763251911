import { Button, Col, List, message, Row, Space, Typography } from 'antd';
import { ReduxServiceFilter } from 'components/atoms/filters/ReduxServiceFilter';
import { Loader } from 'components/atoms/Loader';
import { RecycleCard } from 'components/atoms/RecycleCard';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useGetWarehousePrioritiesQuery, useRecoverWarehousePriorityMutation } from 'redux/services/sonnyFriendly/sonnyFriendly';
import { setWarehousePriorityDescriptionContains, setWarehousePriorityNameContains } from 'redux/services/sonnyFriendly/warehousePriorityParams';
import { setSelectedWarehousePriorities } from 'redux/slices/selectedSotckingLevelsSlice';
import { useAppSelector } from 'redux/store';

export const WarehousePriorityRecycleBinPage = (): JSX.Element => {
  const { selectedWarehousePriorities } = useAppSelector((state) => state.selectedStockingLevels);
  const [recoverWarehouse, { isLoading: isRecovering }] = useRecoverWarehousePriorityMutation();
  const dispatch = useDispatch();
  const { warehousePriorityParams } = useAppSelector((state) => state);

  const navigate = useNavigate();
  const { data, isLoading, isFetching, isError, error } = useGetWarehousePrioritiesQuery({ params: { ...warehousePriorityParams, returnDeletedDataOnly: true } });

  const handleRestore = (): void => {
    try {
      selectedWarehousePriorities.forEach(async (level) => {
        await recoverWarehouse(level.id);
      });
    } catch (err) {
      console.log(err);
      message.error((err as { data: { errorMessage: string } }).data.errorMessage);
    }
  };

  useEffect(() => {
    return (): void => {
      dispatch(setSelectedWarehousePriorities([]));
    };
  }, [dispatch]);

  return (
    <Row gutter={[5, 5]}>
      <Col span={24}>
        <Row justify="space-between">
          <Col>
            <Typography.Title style={{ fontWeight: 300, margin: 0, padding: 0 }} level={5}>
              Search Warehouse Priorities - Recycle Bin
            </Typography.Title>
          </Col>
          <Col>
            <Button onClick={(): void => navigate('/')}>Back to Home</Button>
          </Col>
        </Row>
      </Col>
      <Col span={24}>
        <Row style={{ padding: 12, background: 'white' }}>
          <Space>
            <Col>
              <ReduxServiceFilter label="Warehouse" filterAction={setWarehousePriorityNameContains} />
            </Col>
            <Col>
              <ReduxServiceFilter label="Description" filterAction={setWarehousePriorityDescriptionContains} />
            </Col>
          </Space>
        </Row>
      </Col>
      <Col style={{ marginTop: 5 }} span={24}>
        <Row justify="space-between">
          <Col>
            <Space style={{ width: '100%' }}>
              <Button onClick={() => dispatch(setSelectedWarehousePriorities(data?.data ?? []))}>Select All</Button>
              <Button onClick={() => dispatch(setSelectedWarehousePriorities([]))} type="primary">
                Clear All
              </Button>
            </Space>
          </Col>
          <Col>
            <Button loading={isRecovering} onClick={handleRestore} disabled={!selectedWarehousePriorities.length}>
              Restore Selected
            </Button>
          </Col>
        </Row>
      </Col>
      <Col span={24}>
        <Row>
          <List
            loading={{ spinning: isLoading || isFetching, indicator: <Loader /> }}
            dataSource={data?.data}
            grid={{ gutter: 4, column: 4, xs: 1, sm: 1, md: 2, lg: 2, xl: 3, xxl: 4 }}
            style={{ width: '100%', margin: 0, padding: 0 }}
            renderItem={(item): React.ReactNode => <RecycleCard item={item} />}
          />
        </Row>
      </Col>
    </Row>
  );
};
