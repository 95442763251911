import { PlusOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { useState } from 'react';

interface Props {
  disabled?: boolean;
}

export const AddButton: React.FC<Props> = ({ disabled }: Props): JSX.Element => {
  const [spin, setSpin] = useState(false);

  const animate = (): void => {
    setSpin(true);

    setTimeout(() => setSpin(false), 500);
  };

  return <Button disabled={disabled} htmlType="submit" icon={<PlusOutlined />} style={{ background: 'lightgray', borderRadius: '50%' }} onClick={animate} className={spin ? `btn-spin` : undefined} />;
};
