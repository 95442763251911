import { Select } from 'antd';
import { FormLabel } from 'components/UI/FormLabel';
import { Field, FieldProps, useFormikContext } from 'formik';
import { InventoryStockingLevelsPayload } from 'models/InventoryStockingLevelsModel';
import { CSSProperties, FC, useMemo } from 'react';
import { useGetWarehousePrioritiesQuery } from 'redux/services/sonnyFriendly/sonnyFriendly';
import { useAppSelector } from 'redux/store';
import { Loader } from './Loader';

interface Props {
  fieldName: string;
  type?: string;
  label: string;
  labelStyle?: CSSProperties;
  disabled?: boolean;
}

export const WarehousePrioritySelectField: FC<Props> = ({ fieldName, label, labelStyle, disabled = false }) => {
  const { warehousePriorityParams } = useAppSelector((state) => state);
  const { data, isLoading, isFetching } = useGetWarehousePrioritiesQuery({ params: { ...warehousePriorityParams, overrideSkipTake: true } }, { refetchOnFocus: true });
  const { setFieldValue: setParentFormik } = useFormikContext<InventoryStockingLevelsPayload>();

  // console.log(values, 'look');
  /* ****************** JSX / Variables ****************** */
  const warehousesOptions = useMemo(() => data?.data.map((warehouse) => ({ label: warehouse.name, value: warehouse.id })), [data?.data]);

  return (
    <Field name={fieldName}>
      {({
        field, // { name, value, onChange, onBlur }
        form: { setFieldTouched, setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
        meta
      }: FieldProps): JSX.Element => (
        <div>
          <FormLabel style={labelStyle} label={label} />
          <Select
            {...field}
            disabled={disabled}
            loading={isLoading || isFetching}
            options={warehousesOptions}
            showSearch
            filterOption={(input, option) => (option?.label ?? '').includes(input)}
            filterSort={(optionA, optionB) => (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())}
            dropdownRender={(originalNode) => (isLoading || isFetching ? <Loader message=" " /> : originalNode)}
            onFocus={(): void => setFieldTouched(fieldName, false)}
            style={{ borderColor: meta.error && meta.touched ? '#dc3545' : undefined, width: '100%' }}
            onChange={(val): void => {
              setParentFormik('products', []);
              setFieldValue(fieldName, val);
            }}
          />
          <div className={meta.touched && meta.error ? 'field-error-show' : 'field-error-hide'}>{meta.error}</div>
        </div>
      )}
    </Field>
  );
};
