import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AvailableStockingDimension } from 'models/AvailableStockingDimensions';
import { InventoryStockingLevels } from 'models/InventoryStockingLevelsModel';
import { ReplenishmentDimension } from 'models/ReplenishmentDimensions';
import { WarehousePriorityModel } from 'models/WarehousePriorityModel';

interface InitialState {
  selectedStockingLevels: InventoryStockingLevels[];
  selectedWarehousePriorities: WarehousePriorityModel[];
  selectedReplenishmentDimensions: ReplenishmentDimension[];
  selectedAvailableStockingDimensions: AvailableStockingDimension[];
}

const initialState: InitialState = {
  selectedStockingLevels: [],
  selectedWarehousePriorities: [],
  selectedReplenishmentDimensions: [],
  selectedAvailableStockingDimensions: []
};

export const selectedStockingLevels = createSlice({
  name: 'selectedStockingLevels',
  initialState,
  reducers: {
    setSelectedStockingLevels: (state, { payload }: PayloadAction<InventoryStockingLevels[]>) => {
      state.selectedStockingLevels = payload;
    },
    addStockingLevel: (state, { payload }: PayloadAction<InventoryStockingLevels>) => {
      state.selectedStockingLevels = [...state.selectedStockingLevels, payload];
    },
    removeStockingLevel: (state, { payload }: PayloadAction<InventoryStockingLevels>) => {
      state.selectedStockingLevels = state.selectedStockingLevels.filter((item) => item.id !== payload.id);
    },
    setSelectedWarehousePriorities: (state, { payload }: PayloadAction<WarehousePriorityModel[]>) => {
      state.selectedWarehousePriorities = payload;
    },
    addWarehousePriority: (state, { payload }: PayloadAction<WarehousePriorityModel>) => {
      state.selectedWarehousePriorities = [...state.selectedWarehousePriorities, payload];
    },
    removeWarehousePriority: (state, { payload }: PayloadAction<WarehousePriorityModel>) => {
      state.selectedWarehousePriorities = state.selectedWarehousePriorities.filter((item) => item.id !== payload.id);
    },
    setSelectedReplenishmentDimensions: (state, { payload }: PayloadAction<ReplenishmentDimension[]>) => {
      state.selectedReplenishmentDimensions = payload;
    },
    addReplenishmentDimensions: (state, { payload }: PayloadAction<ReplenishmentDimension>) => {
      state.selectedReplenishmentDimensions = [...state.selectedReplenishmentDimensions, payload];
    },
    removeReplenishmentDimensions: (state, { payload }: PayloadAction<ReplenishmentDimension>) => {
      state.selectedReplenishmentDimensions = state.selectedReplenishmentDimensions.filter((item) => item.id !== payload.id);
    },
    setSelectedAvailableStockingDimensions: (state, { payload }: PayloadAction<AvailableStockingDimension[]>) => {
      state.selectedAvailableStockingDimensions = payload;
    },
    addsSelectedAvailableStockingDimensions: (state, { payload }: PayloadAction<AvailableStockingDimension>) => {
      state.selectedAvailableStockingDimensions = [...state.selectedAvailableStockingDimensions, payload];
    },
    removeSelectedAvailableStockingDimensions: (state, { payload }: PayloadAction<AvailableStockingDimension>) => {
      state.selectedAvailableStockingDimensions = state.selectedAvailableStockingDimensions.filter((item) => item.id !== payload.id);
    }
  }
});

export const {
  setSelectedStockingLevels,
  addStockingLevel,
  removeStockingLevel,
  setSelectedWarehousePriorities,
  addWarehousePriority,
  removeWarehousePriority,
  setSelectedReplenishmentDimensions,
  addReplenishmentDimensions,
  removeReplenishmentDimensions,
  setSelectedAvailableStockingDimensions,
  addsSelectedAvailableStockingDimensions,
  removeSelectedAvailableStockingDimensions
} = selectedStockingLevels.actions;
