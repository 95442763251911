import { Col, Row, Typography } from 'antd';
import { InventoryStockingGrid } from 'components/molecules/InventoryStockingGrid';
import { MouseEvent, useState } from 'react';
import { useSpring } from 'react-spring';
import { useMeasure } from 'react-use';

export const StockingLevels: React.FC = (): JSX.Element => {
  const [showRequestTypes, setShowRequestTypes] = useState(true);

  const handleToggleShowRequestTypes = (ev: MouseEvent<HTMLElement>): void => {
    ev.preventDefault();
    ev.stopPropagation();
    setShowRequestTypes((prevState) => !prevState);
  };

  const [ref, { height }] = useMeasure();

  const expandAnimation = useSpring({
    config: { friction: 12, bounce: 0 },
    height: showRequestTypes ? height : 0
  });

  return (
    <Col span={24} style={{ marginBottom: showRequestTypes ? 10 : 0 }}>
      <Row onClick={handleToggleShowRequestTypes} style={{ padding: 15, borderRadius: 5, cursor: 'pointer' }} justify="space-between">
        <Typography.Title style={{ fontWeight: 400, padding: 0, margin: 0, fontSize: 22 }} level={3}>
          Stocking Levels
        </Typography.Title>
      </Row>
      <InventoryStockingGrid />
    </Col>
  );
};
