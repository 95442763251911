import { DeleteOutlined } from '@ant-design/icons';
import { Button, Col, Divider, Row, Typography } from 'antd';
import { CaretSpinIcon } from 'components/atoms/CaretSpinIcon';
import { ReduxServiceFilter } from 'components/atoms/filters/ReduxServiceFilter';
import { LegacyRef, MouseEvent, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { animated, useSpring } from 'react-spring';
import { useMeasure } from 'react-use';
import { setReplenishmentDimensionDescriptionContains, setReplenishmentDimensionNameContains } from 'redux/services/sonnyFriendly/replenishmentDimensionParams';
import { ReplenishmentDimensionsTable } from './ReplenishmentDimensionsTable';

export const ReplenishmentDimensions: React.FC = (): JSX.Element => {
  const navigate = useNavigate();
  const { divisionId } = useParams();

  const [showRequestTypes, setShowRequestTypes] = useState(false);

  const handleRecycleBinNavigate = (): void => {
    navigate('/replenishment-dimesnion/recycle-bin');
  };

  const handleToggleShowRequestTypes = (ev: MouseEvent<HTMLElement>): void => {
    ev.preventDefault();
    ev.stopPropagation();
    setShowRequestTypes((prevState) => !prevState);
  };

  const [ref, { height }] = useMeasure();

  const expandAnimation = useSpring({
    config: { friction: 12, bounce: 0 },
    height: showRequestTypes ? height : 0
  });

  // if (isLoading) return <BlockLoader direction="loader loader--slideUp" />;

  return (
    <Col span={24} style={{ marginBottom: showRequestTypes ? 10 : 0 }}>
      <Row onClick={handleToggleShowRequestTypes} style={{ padding: 15, background: '#b0b2bc', borderRadius: 5, cursor: 'pointer' }} justify="space-between">
        <Typography.Title style={{ fontWeight: 400, padding: 0, margin: 0, fontSize: 22 }} level={3}>
          Replenishment Dimensions
        </Typography.Title>
        <Button style={{ width: 115 }} type="text" onClick={handleToggleShowRequestTypes} key="show-divisions" icon={<CaretSpinIcon isOpen={showRequestTypes} />}>
          {showRequestTypes ? 'Collapse' : 'Expand'}
        </Button>
      </Row>
      <Row style={{ background: 'transparent', padding: '0 1.25px' }}>
        <animated.div style={{ ...{ minHeight: 10, overflow: 'hidden', width: '100%' }, ...expandAnimation }}>
          {showRequestTypes && (
            <div ref={ref as LegacyRef<HTMLDivElement>} style={{ width: '100%' }}>
              <Col span={24}>
                <Row gutter={8} justify="space-between" style={{ marginTop: 8, background: 'white', padding: 20 }}>
                  <Row gutter={8}>
                    <Col>
                      <ReduxServiceFilter label="Replenishment Dimension ID" filterAction={setReplenishmentDimensionNameContains} />
                      <ReduxServiceFilter label="Description" filterAction={setReplenishmentDimensionDescriptionContains} />
                    </Col>
                  </Row>
                  <Col>
                    <Button onClick={(): void => navigate(`/replenishment-dimension/create`)}>Add Dimension</Button>
                    <Button type="primary" style={{ marginLeft: 5 }} icon={<DeleteOutlined />} onClick={handleRecycleBinNavigate}>
                      Recycle-Bin
                    </Button>
                  </Col>
                </Row>
              </Col>
              <Divider style={{ margin: '8px 0' }} />
              <ReplenishmentDimensionsTable />
            </div>
          )}
        </animated.div>
      </Row>
    </Col>
  );
};
