import { DeleteOutlined, SaveOutlined, WarningFilled } from '@ant-design/icons';
import { Button, Col, Dropdown, Menu, Modal, Row, Tooltip, Typography } from 'antd';
import { FormikValues, useFormikContext } from 'formik';
import { useGetPermissions } from 'hooks/useAuthentication';
import React from 'react';
import { useNavigate } from 'react-router-dom';

interface EditControlProps {
  title: string;
  submitButtonProps: {
    disabled?: boolean;
    handleSubmit: () => void;
    tooltip?: string;
  };
  deleteButtonProps: {
    handleDelete: () => Promise<void>;
  };
  description: string;
}

export const EditPageControls: React.FC<EditControlProps> = ({ title, submitButtonProps, deleteButtonProps, description }) => {
  const nav = useNavigate();

  const [modal, contextHolder] = Modal.useModal();
  const { values, setValues } = useFormikContext<FormikValues>();

  const { userRole } = useGetPermissions();

  const { handleSubmit, disabled, tooltip } = submitButtonProps;
  const { handleDelete } = deleteButtonProps;

  const handleSetExit = (): void => {
    setValues({ ...values, exit: true });
    handleSubmit();
  };

  const menuJSX = (
    <Menu disabledOverflow={true}>
      <Menu.Item onClick={handleSubmit} key="1">
        Save
      </Menu.Item>
    </Menu>
  );

  return (
    <Row justify="space-between" align="middle" style={{ marginBottom: 16 }}>
      <Col>
        <Typography.Title style={{ fontWeight: 400, padding: 0, margin: 0 }} level={3}>
          {title}
        </Typography.Title>
      </Col>
      <Col style={{ display: 'flex' }}>
        <Button style={{ marginRight: 5 }} onClick={(): void => nav('/')}>
          Back
        </Button>
        <Tooltip title={tooltip ?? undefined}>
          <Dropdown.Button onClick={handleSetExit} disabled={disabled} style={{ marginRight: 8 }} overlay={menuJSX} type="primary" icon={<SaveOutlined />}>
            Save and Exit
          </Dropdown.Button>
        </Tooltip>
        {contextHolder}
        <Button
          danger
          hidden={userRole === 'user'}
          onClick={(): void => {
            modal.confirm({
              onOk: handleDelete,
              title: `Are you sure you want to delete this ${description}?`,
              okText: 'Delete',
              icon: <WarningFilled style={{ color: 'red' }} />,
              okButtonProps: { style: { background: 'red', border: 'none' } },
              prefixCls: 'ant-modal'
            });
          }}
          icon={<DeleteOutlined />}
          style={{ color: 'red', marginRight: 8 }}>
          Delete
        </Button>

        <Tooltip title="Coming soon">
          <Button type="primary" disabled>
            Audit History
          </Button>
        </Tooltip>
      </Col>
    </Row>
  );
};
