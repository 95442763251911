import { useAuth0 } from '@auth0/auth0-react';
import Cookies from 'js-cookie';
import { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { setAccessToken, setAcuityContext, setUser } from 'redux/slices/appSlice';
import { useAppDispatch, useAppSelector } from 'redux/store';

export interface AcuityContext {
  selectedCustomer: {
    business: Business;
    erpId: string;
    id: string;
    name: string;
  };
}
export interface Business {
  applications: Application[];
  dataAreaId: string;
  erpId: string;
  id: string;
  name: string;
}
export interface Application {
  accessPermissionName: string;
  id: string;
  name: string;
  description: string;
  url: string;
}

export const useGetPermissions = (): { userRole: 'user' | 'admin' } => {
  const { accessToken } = useAppSelector((state) => state.app);

  const user: { permissions: string[] } = accessToken ? JSON.parse(window.atob(accessToken.split('.')[1])) : {};

  const isAdmin = user?.permissions?.some((perm) => perm.toLocaleLowerCase() === 'UseApplication:InventoryStockingLevelsAsAdmin'.toLocaleLowerCase());

  return { userRole: isAdmin ? 'admin' : 'user' };
};

export const useAuthentication = (): { isLoading: boolean; accessToken: string | null } => {
  const { accessToken } = useAppSelector((state) => state.app);
  const { isAuthenticated, loginWithRedirect, isLoading, user, getAccessTokenSilently } = useAuth0();
  const [cookieNotFound, setCookieNotFound] = useState(false);
  const { userRole } = useGetPermissions();
  const { pathname } = useLocation();
  const nav = useNavigate();

  const isAdminRoute = pathname?.includes('available-stocking-dimension') || pathname.includes('replenishment-dimension') || pathname.includes('warehouse-priority');

  if (accessToken && userRole === 'user' && isAdminRoute) nav('/unauthorized');

  const dispatch = useAppDispatch();

  const fetchAccessToken = useCallback(async () => {
    const token = await getAccessTokenSilently();

    dispatch(setAccessToken(token));
  }, []);

  useEffect(() => {
    const cookie = Cookies.get(`${process.env.REACT_APP_COOKIE_PREFIX}_acuity_context`);

    const cookieJSON: AcuityContext = cookie ? JSON.parse(cookie) : undefined;

    if (cookieJSON?.selectedCustomer) {
      dispatch(setAcuityContext(cookieJSON));
    } else {
      setCookieNotFound(true);
    }

    if (cookieNotFound) {
      window.location.href = process.env.REACT_APP_ERNIE_REDIRECT_URL || '';
    }
  }, [cookieNotFound]);

  // Check if User is logged in, if not, redirect them to login screen
  useEffect(() => {
    if (!isAuthenticated && !isLoading) {
      loginWithRedirect({ appState: { targetUrl: '/' } });
    }
    if (isAuthenticated && accessToken === null) {
      fetchAccessToken();
    }
  }, [accessToken, isAuthenticated, isLoading]);

  useEffect(() => {
    if (user) {
      dispatch(setUser(user));
    }
  }, [user]);

  return { isLoading, accessToken };
};
