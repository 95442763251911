import { StockingProducts } from 'models/InventoryStockingLevelsModel';
import { useEffect, useState } from 'react';
import { FilterState } from 'redux/slices/filterSlice';

export const useGetFilteredData = (filterState: FilterState, data: StockingProducts[]): { filteredData: StockingProducts[] | undefined } => {
  const [filteredData, setFilteredData] = useState<StockingProducts[] | undefined>(data);

  function recursiveFilter(data: StockingProducts[], params: FilterState): StockingProducts[] | undefined {
    if (Object.keys(params).length === 0) {
      return data;
    }
    const [key, value] = Object.entries(params)[0];

    const filteredData = data.filter((item) => {
      const paramKey = key as keyof FilterState;

      switch (paramKey) {
        case 'warehouseContains': {
          return String(item['warehouseId']).toLowerCase().includes(String(value).toLowerCase());
        }

        case 'isActive': {
          return value ? !item['isDeleted'] : item['isDeleted'];
        }

        case 'productNumberContains': {
          return String(item['productNumber']).toLowerCase().includes(String(value).toLowerCase());
        }

        case 'manufacturerContains': {
          return String(item['manufacturer']).toLowerCase().includes(String(value).toLowerCase());
        }

        case 'categoryContains': {
          return String(item['category']).toLowerCase().includes(String(value).toLowerCase());
        }
      }
    });

    const remainingFilters = { ...params };

    delete remainingFilters[key as keyof FilterState];

    // Recursively filter using the remaining filters.
    return recursiveFilter(filteredData, remainingFilters);
  }
  useEffect(() => {
    const filtersToUse: FilterState = Object.fromEntries(Object.entries(filterState).filter(([key, value]) => value !== undefined));

    setFilteredData(recursiveFilter(data, filtersToUse));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterState, data]);

  return { filteredData };
};
