import { skipToken } from '@reduxjs/toolkit/dist/query';
import { Card, Col, message, Row, Space, Spin, Typography } from 'antd';
import { BlockLoader } from 'components/atoms/BlockLoader';
import { FieldInput } from 'components/atoms/FieldInput';
import { Loader } from 'components/atoms/Loader';
import { EditPageControls } from 'components/molecules/EditPageControls';
import { AvailableStockingDimensionCombinationsTable } from 'components/organisms/AvailableStockingDimensionCombinationsTable';
import { FormikProvider, useFormik } from 'formik';
import _ from 'lodash';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDeleteAvailableStockingDimensionMutation, useGetAvailableStockingDimensionQuery, useUpdateAvailableStockingDimensionMutation } from 'redux/services/sonnyFriendly/sonnyFriendly';
import { availableStockingDimensionsSchema } from './CreateAvailableStockingDimension';

export interface AvailableStockingDimension {
  name: string;
  description: string;
  dimensions: { ownerId: string; conditionId: string; dispositionId: string }[];
  exit: boolean;
}

const GeneralSection: React.FC = () => {
  return (
    <Card
      style={{ height: '100%' }}
      headStyle={{ background: 'rgb(206, 213, 242)' }}
      title={
        <Typography.Title level={4} style={{ fontWeight: 400, fontSize: 22 }}>
          General
        </Typography.Title>
      }>
      <Row gutter={[30, 10]}>
        <Col span={12}>
          <Space direction="vertical" style={{ width: '100%' }} size={15}>
            <FieldInput fieldName="name" label="Name" />
          </Space>
        </Col>
        <Col span={12}>
          <Space direction="vertical" style={{ width: '100%' }} size={15}>
            <FieldInput fieldName="description" label="Description" />
          </Space>
        </Col>
      </Row>
    </Card>
  );
};

export const EditAvailableStockingDimension: React.FC = () => {
  const { availableStockingDimensionId } = useParams();
  const nav = useNavigate();

  const { data, isLoading } = useGetAvailableStockingDimensionQuery(availableStockingDimensionId ?? skipToken, { skip: !availableStockingDimensionId });
  const [updateAvailableStockingDimensions, { isLoading: isUpdating }] = useUpdateAvailableStockingDimensionMutation();
  const [deleteAvailableStockingDimensions, { isLoading: isDeleting }] = useDeleteAvailableStockingDimensionMutation();

  const formik = useFormik<AvailableStockingDimension>({
    validationSchema: availableStockingDimensionsSchema,
    enableReinitialize: true,
    initialValues: {
      name: data?.name ?? '',
      description: data?.description ?? '',
      dimensions: data?.dimensions ?? [],
      exit: false
    },
    onSubmit: async (values) => {
      const { exit, ...payload } = values;

      try {
        await updateAvailableStockingDimensions({ availableStockDimensionId: availableStockingDimensionId ?? '', payload: { ...payload, isActive: true } }).unwrap();
        message.success(`${values.name} successfully updated!`);
        if (exit) {
          nav('/');
        }
      } catch (e) {
        console.log(e, 'error');
        message.error((e as { data: { errorMessage: string } })?.data?.errorMessage || 'An error occurred while submitting the form. Please try again.');
        formik.setValues({ ...formik.values, exit: false });
      }
    }
  });
  const handleDelete = async (): Promise<void> => {
    if (!availableStockingDimensionId) return message.error('An error occured (no id) the team has been notified');
    try {
      await deleteAvailableStockingDimensions(availableStockingDimensionId);

      nav('/');
      message.success(`Available stocking dimension was succesfully deleted`);
    } catch (err) {
      console.log(err);
      message.error((err as { data: { errorMessage: string } }).data.errorMessage);
    }
  };

  if (isLoading) return <BlockLoader direction="loader loader--slideUp" />;

  const isValid = formik.isValid && !_.isEqual(formik.values, formik.initialValues);

  return (
    <FormikProvider value={formik}>
      <Spin
        spinning={isUpdating || isDeleting || formik.isSubmitting}
        indicator={<Loader message={isUpdating ? 'Updating available stocking dimension' : isDeleting ? 'Deleting available stocking dimension' : ''} />}>
        <Space direction="vertical" style={{ width: '100%', paddingBottom: 100 }}>
          <EditPageControls
            title="Edit Stocking Dimension"
            description="stocking dimension"
            submitButtonProps={{ handleSubmit: () => formik.submitForm(), disabled: !isValid, tooltip: !formik.values.dimensions.length ? 'You must add one OCD combination to save' : '' }}
            deleteButtonProps={{ handleDelete: handleDelete }}
          />
          <GeneralSection />
          <AvailableStockingDimensionCombinationsTable />
        </Space>
      </Spin>
    </FormikProvider>
  );
};
