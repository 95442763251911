import { Button, Col, message, Row } from 'antd';
import { useFormikContext } from 'formik';
import { InventoryStockingLevelsPayload, StockingProducts } from 'models/InventoryStockingLevelsModel';
import React from 'react';
import * as xlsx from 'xlsx';

type Props = {
  dataToExport?: StockingProducts[];
};

export const ExportProductsButton: React.FC<Props> = ({ dataToExport }) => {
  const {
    values: { name }
  } = useFormikContext<InventoryStockingLevelsPayload>();

  const updatedProducts = dataToExport?.map(({ productNumber, warehouseId, manufacturer, category, minimumQuantity, maximumQuantity, reasonCode, notes }) => ({
    ['Product Number']: productNumber,
    Warehouse: warehouseId,
    Manufacturer: manufacturer,
    Category: category,
    Minimum: minimumQuantity,
    Maximum: maximumQuantity,
    ['Reason Code']: reasonCode,
    Notes: notes
  }));

  const handleExportLineItems = async (): Promise<void> => {
    if (!updatedProducts) return message.error('No products found, please add some or refresh the page');
    const worksheeet = xlsx.utils.json_to_sheet(updatedProducts);
    const workbook = xlsx.utils.book_new();

    xlsx.utils.book_append_sheet(workbook, worksheeet, 'products');
    xlsx.writeFile(workbook, `${name}-products.xlsx`);
  };

  return (
    <Row>
      <Col>
        <Button onClick={handleExportLineItems}>Export Products</Button>
      </Col>
    </Row>
  );
};
